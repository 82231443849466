import React, { Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import config from './config';
import { download } from './getVcardUser';
import { FormattedMessage } from 'react-intl';
import { BUs } from './Utils/BU.js';
import { countryNames, countryArrraySortedByName, countryArrraySortedByCode } from './Utils/country.js';
import { languages } from './Utils/languages.js';
import PublicProfileDisclaimer from './modals/public-profile-disclaimer.jsx';
import Modal from 'react-modal';
import QRCode from "react-qr-code";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '1000px'
  }
};

export default withOktaAuth(class Profile extends Component {
  constructor() {
    const customEnum = {
      BUs: BUs,
      CountryNames: countryNames,
      Countries: countryArrraySortedByName,
      CountriesSortedByCode: countryArrraySortedByCode,
      Languages: languages
    }

    super();
    this.state = {
      isAuthenticated: false,
      showIntuitiveResults: true,
      intuitiveResults: [],
      userinfo: null,
      ready: false,
      results: null,
      failed: false,
      enum: customEnum,
      isEditMode: false,
      isEditable: false,
      isCurrentUser: false,
      editableFields: [],
      formError: {},
      isFormError: true,
      assistantUserName: "",
      managerUserName: "",
      addresses: [],
      physicalDeliveryOfficeNames: [],
      modalIsOpen: false,
      showQRCode: false,
      userDomain: '',
      publicHash: null
    };
    this.handleFile = this.handleFile.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  async componentDidMount() {
    let _user = await this.props.oktaAuth.getUser();
    this.setState({ userinfo: _user, isAuthenticated: this.props.authState?.isAuthenticated }, async () => {
      await this.getUserProfile();
      this.setState({ ready: true });
    });
    document.addEventListener('click', this.handleClick);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps?.authState && this.props?.authState && prevProps.authState.isAuthenticated !== this.props.authState.isAuthenticated) {
      this.setState({ isAuthenticated: this.props.authState?.isAuthenticated });
    }
  }

  async componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }


  async getUserProfile() {
    try {
      const accessToken = this.props.authState.accessToken.accessToken;
      let searchstring = this.props.location.search.replace("?", "");
      const response = await fetch(config.env.resourceServer.getOktaProfileUrl + "?userId=" + (searchstring ? searchstring : this.state.userinfo.preferred_username), {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
      });

      if (response.status !== 200) {
        return;
      }

      const data = await response.json();

      if(data.preferredLanguage && data.preferredLanguage.length == 2){
        var retrievedLanguage = languages.find(x => x.includes(data.preferredLanguage));
        if(retrievedLanguage){
          data.preferredLanguage = retrievedLanguage;
        }
      }

      this.setState({ results: data, publicHash: data.publicHash, failed: false }, async () => {
        this.getPhoto(data.login, false);
        this.getBU();
        await this.getEditableFields();
      });

      if (data.C_msDSCloudExtensionAttribute3) {
        this.getUserGBUName(data.C_msDSCloudExtensionAttribute3);
      }

      if (data.C_assistant) {
        this.getUserInfoByDistinguishedName(data.C_assistant, false);
      }

      if (data.manager) {
        this.getUserInfoByDistinguishedName(data.manager, true);
      }
    } catch (err) {
      console.error(err);
    }
  }

  getBU() {
    let bu = this.state.enum.BUs.find(bu => bu.id === this.state.results.division);
    let results = this.state.results;
    if (bu) {
      results.division = bu;
    }
    else {
      results.division = {
        id: this.state.results.division,
        shortName: this.state.results.division,
        name: this.state.results.division
      };
    }
    this.setState({ results: results });
  }

  async getPhoto(userId, clearCache) {
    try {
      var size = "240x240";
      if (clearCache) {
        size = new Date().getTime();
      }
      const accessToken = this.props.authState.accessToken.accessToken;
      const response = await fetch(config.env.resourceServer.getOktaUserPhotoUrl + "?userId=" + userId + "&size=" + size, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
      });

      if (response.status !== 200) {
        return;
      }

      let data = await response.json();
      this.setState({
        userPhoto: data.image
      });
    } catch (err) {
      console.error(err);
    }
  }

  async updatePhoto(userId, file) {
    try {
      const accessToken = this.props.authState.accessToken.accessToken;
      const response = await fetch(config.env.resourceServer.updateOktaUserPhotoUrl + "?userId=" + userId, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: file,
      });

      if (response.status !== 200) {
        return;
      }
      try {
        const data = await response.json();
        if (data.error) {
          console.error(data.error);
          alert(data.error.message);
        }
      }
      catch (error) {
        this.getPhoto(userId, true);
      }

    } catch (err) {
      console.error(err);
    }
  }

  handleFile(e) {
    const content = e.target.result;
    this.updatePhoto(this.state.userinfo.preferred_username, content);
  }

  onChangeFile(event) {
    if (event.target.files.length > 0) {
      event.stopPropagation();
      event.preventDefault();
      if (event.target.files[0].type !== 'image/jpeg') {
        alert("The selected file is not a jpg file.");
        return
      }
      if (event.target.files[0].size > 4194000) {
        alert("The image should be smaller than 4MB.");
        return
      }
      var reader = new FileReader();
      reader.onloadend = this.handleFile;
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  async getEditableFields() {
    try {
      let accessToken = this.props.authState.accessToken.accessToken;

      //Retrieve the domain from user DistinguishedName
      let dnInfos = this.state.results.C_distinguishedName.replaceAll(',', '=').split('=');
      let domain = "";
      for (let i = 0; i < dnInfos.length; i++) {
        if (dnInfos[i] === "DC") {
          domain += dnInfos[i + 1] + ".";
          i++;
        }
      }
      domain = domain.slice(0, -1);

      let organizationID = this.state.results.C_msDSCloudExtensionAttribute2 ?? null;
      let organizationEditableFields = null;
      //Retrieve the SelfServiceOrganization config from the orgID
      if (organizationID) {
        let organizationConfigResult = await fetch(`${config.env.resourceServer.selfServiceOrganizations}/${organizationID.toUpperCase()}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
        });
        organizationEditableFields = await organizationConfigResult.json();
      }

      //Retrieve the SelfServiceDomain config from the domain
      let domainConfigResult = await fetch(`${config.env.resourceServer.selfServiceDomains}/${domain.toUpperCase()}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
      });
      let domainEditableFields = await domainConfigResult.json();

      //Check if the domain was retrieved if it's the case use org setting if retrieved or the domain settings
      let editableFields = domainEditableFields ? organizationEditableFields ?? domainEditableFields : [];

      editableFields.push('linkedInURI');
      editableFields.push('xURI');

      if (editableFields && editableFields.length > 0 && this.state.userinfo.preferred_username === this.state.results.login) {
        this.setState({ isEditable: true, isCurrentUser: true, editableFields: editableFields, userDomain: domain });
      } else if (this.state.userinfo.preferred_username === this.state.results.login) {
        this.setState({ isCurrentUser: true });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async switchEditMode() {
    let isEdit = !this.state.isEditMode;
    if (isEdit) {
      try {
        let state = this.state;
        let accessToken = this.props.authState.accessToken.accessToken;
        let response = await fetch(`${config.env.resourceServer.addresses}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
        });
        let data = await response.json();
        state.addresses = data;

        let formError = {};
        let isFormError = false;
        state["assistantUserName"] = this.state.results["assistantUserName"];
        this.state.editableFields.forEach(field => {
          formError[field] = this.getInputFormatError(field, this.state.results[field]);
          if (formError[field]) {
            isFormError = true;
          }
          state[field] = this.state.results[field];
        });
        state.isFormError = isFormError;
        state.formError = formError;
        this.setState(state, () => {
          this.getCountriesByUserBu();
          this.getCitiesByCountry();
          this.getstreetAddressesByCity();
          this.getPhysicalDeliveryOfficeNamesByAddress();
          this.setState({
            isEditMode: isEdit
          });
        });
      } catch (error) {
        console.error(error);
      }
    } else {
      this.setState({
        isEditMode: isEdit
      });
    }
  }

  getInputFormatError(field, value) {
    let nullable = true;
    let formatReg = /.{0,1024}/gm;
    let maxLengthReg = /^.{0,1024}$/gm;
    let notNullableReg = /.{1,1024}/gm;
    if (value === undefined || value === null) {
      value = "";
    }

    switch (field) {
      case 'C_facsimileTelephoneNumber':
      case 'mobilePhone':
      case 'primaryPhone':
        formatReg = /^\+[1-9]\d{7,}$/g;
        maxLengthReg = /^.{0,62}$/g;
        break;
      case 'C_countryname':
        nullable = false;
        break;
      case 'C_ipPhone':
        maxLengthReg = /^.{0,64}$/g;
        break;
      case 'C_houseIdentifier​':
      case 'city':
      case 'state':
        maxLengthReg = /^.{0,128}$/g;
        break;
      case 'zipCode':
        maxLengthReg = /^.{0,20}$/g;
        break;
      case 'xURI':
        formatReg = /^https:\/\/(www\.)?twitter.com\/\S+$/gi;
        break;
      case 'linkedInURI':
        formatReg = /^https:\/\/(www\.)?linkedin.com\/\S+$/gi;
      default:
        break;
    }

    if(field === 'preferredLanguage'){
      if(value && !this.state.enum.Languages.includes(value)){
        return 'error.e2';
      }
    }

    if (!maxLengthReg.test(value)) {
      return 'error.e1';//too long value
    }
    if (!nullable && !notNullableReg.test(value)) {
      return 'error.e3';//cannot be null
    } else if (notNullableReg.test(value) && !formatReg.test(value)) {
      return 'error.e2';//bad format
    }

    return null;
  }

  handleInputChange(event, isSelected) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    let formError = this.state.formError;
    let isFormError = false;

    if (name === "assistantUserName") {
      if (value === "") {
        target.distinguishedName = "";
      }
      this.setState({
        assistantUserName: value,
        C_assistant: target.distinguishedName
      });
      if (!isSelected) {
        clearTimeout(this.timeout);

        // Make a new timeout set to go off in 800ms
        this.timeout = setTimeout(() => {
          this.getIntuitiveInfo(value);
          if (!this.state.showIntuitiveResults) {
            this.setState({ showIntuitiveResults: true });
          }
        }, 500);
      }
    }

    this.state.editableFields.forEach(field => {
      if (name === field) {
        formError[field] = this.getInputFormatError(field, value);
      } else {
        formError[field] = this.getInputFormatError(field, this.state[field]);
      }
      if (formError[field]) {
        isFormError = true;
      }
    });

    this.setState({
      [name]: value,
      isFormError: isFormError,
      formError: formError
    }, () => {
      if (name === "C_countryname") {
        this.setState({
          city: "",
          zipCode: "",
          streetAddress: "",
          C_houseIdentifier: ""
        });
        this.getCitiesByCountry(true);
      }
      if (name === "city") {
        this.setState({
          zipCode: "",
          streetAddress: "",
          C_houseIdentifier: ""
        },
          () => {
            this.getstreetAddressesByCity(true);
            this.getPhysicalDeliveryOfficeNamesByAddress(true);
            this.getZipCodeByCity();
          });

      }
      if (name === "streetAddress") {
        this.getPhysicalDeliveryOfficeNamesByAddress(true);
      }
      if (name === "C_houseIdentifier") {
        this.getAddress();
      }
    });
  }

  async getUserGBUName(gbuCode) {
    try {
      var currentAccessToken = this.props.authState.accessToken.accessToken;
      const response = await fetch(config.env.resourceServer.getGBUsUrl + "?Code=" + gbuCode + "&order=Name ASC&select=Name,Code&index=0&itemPerPage=1", {
        headers: {
          Authorization: `Bearer ${currentAccessToken}`
        },
      });

      if (response.status !== 200) {
        return;
      }

      const data = await response.json();
      if (data && Array.isArray(data) && data[0] && data[0].Name) {
        let results = this.state.results;
        results.gbuName = data[0].Name;
        this.setState({ results: results });
      }
    } catch (err) {
      console.error(err);
    }

  }

  async getUserInfoByDistinguishedName(userDistinguishedName, userIsManager) {
    try {
      const accessToken = this.props.authState.accessToken.accessToken;
      var escapedDistinguishedName = userDistinguishedName.replace("\\", "\\\\");
      escapedDistinguishedName = encodeURI(userDistinguishedName);
      const response = await fetch(config.env.resourceServer.getOktaProfileUrl + "?distinguishedName=" + escapedDistinguishedName, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      if (response.status !== 200) {
        console.error(`Cannot retrieve ${userIsManager ? 'manager' : 'assistant'}!`);
        return;
      }

      const userData = await response.json();
      let results = this.state.results;
      if (userIsManager) {
        results.managerUserId = userData.login;
        results.managerUserName = `${userData.firstName} ${userData.lastName}`;
      } else {
        results.assistantUserId = userData.login;
        results.assistantUserName = `${userData.firstName} ${userData.lastName}`;
      }

      this.setState({ results: results });
    } catch (err) {
      console.error(err);
    }
  }

  async getIntuitiveInfo(value) {
    try {
      let results = [];
      this.setState({ intuitiveResults: results });
      const accessToken = this.props.authState.accessToken.accessToken;
      let url = new URL(config.env.resourceServer.getOktaUsersUrl);

      let formatedGlobalQuery = value;
      let updatedGroupIdQuery = "";
      let updatedPhoneNumberQuery = "";

      //Handle GroupID format
      if (value.match(/^([a-zA-Z]{2}[0-9]{4})(-[a-zA-Z])?$|^([a-zA-Z]{3}[0-9]{3})(-[a-zA-Z])?$|^([a-zA-Z]{4}[0-9]{2})(-[a-zA-Z])?$/g)) {
        updatedGroupIdQuery = formatedGlobalQuery.slice();
        formatedGlobalQuery = "";
      } else if (value.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s0-9]*$/g)) {
        //Handle phone number format
        updatedPhoneNumberQuery = formatedGlobalQuery.substr(0, 1) === "0" ? formatedGlobalQuery.slice(1).replace(/[^0-9]/g, "") : formatedGlobalQuery.slice().replace(/[^0-9]/g, "");
        formatedGlobalQuery = "";
      }

      let params = {
        globalQuery: formatedGlobalQuery,
        groupIdQuery: updatedGroupIdQuery,
        primaryPhoneQuery: updatedPhoneNumberQuery,
        domainQuery: this.state.userDomain.substring(this.state.userDomain.indexOf('.') + 1),
        limit: 3,
        after: 0
      }
      url.search = new URLSearchParams(params);
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      if (response.status !== 200) {
        return;
      }

      const data = await response.json();
      data.forEach((element) => {
        results.push(element);
      });

      this.setState({ intuitiveResults: results });

      data.forEach((element) => {
        this.getIntuitivePhoto(element.login);
      });
    } catch (err) {
      /* eslint-disable no-console */
      console.error(err);
    }
  }

  async getIntuitivePhoto(userId) {
    try {
      const accessToken = this.props.authState.accessToken.accessToken;
      const response = await fetch(config.env.resourceServer.getOktaUserPhotoUrl + "?userId=" + userId + "&size=240x240", {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
      });

      // if (response.status !== 200) {
      //   return;
      // }

      const data = await response.json();

      let domUser = document.getElementById(`intuitive-${userId}`);
      if (domUser) {
        domUser.src = data.image ? data.image : "img/default_profile_photo.png";
      }

    } catch (err) {
      console.error(err);
    }
  }

  handleClick() {
    if (this.state.showIntuitiveResults) {
      this.setState({ showIntuitiveResults: false });
    }
  }

  async handleSubmit() {
    this.setState({ ready: false });
    try {
      let body = {};
      let changeDetected = false;
      for (let i = 0; i < this.state.editableFields.length; i++) {
        if (this.state.editableFields[i] === "countryCode") {
          continue;
        }
        if (this.state[this.state.editableFields[i]] !== undefined) {
          body[this.state.editableFields[i]] = this.state[this.state.editableFields[i]];
          if (this.state.editableFields[i] === "C_countryname") {
            let country = this.state.enum.Countries.find(country => country.name.toUpperCase() === body["C_countryname"].toUpperCase());
            body["countryCode"] = country ? country.id : "";
          }
        } else {
          body[this.state.editableFields[i]] = this.state.results[this.state.editableFields[i]] ? this.state.results[this.state.editableFields[i]] : "";
        }
        if (body[this.state.editableFields[i]] !== this.state.results[this.state.editableFields[i]]) {
          changeDetected = true;
        }
      }
      if (changeDetected) {
        let accessToken = this.props.authState.accessToken.accessToken;
        let response = await fetch(config.env.resourceServer.updateUserProfile, {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        });
        let profile = await response.json();
        this.setState({
          results: profile,
          isEditMode: false,
          ready: true
        }, () => this.getBU());
      } else {
        this.setState({
          isEditMode: false,
          ready: true
        });
      }
    } catch (err) {
      console.error(err);
    }
    this.getPhoto(this.state.results.login, false);
    if (this.state.results.C_assistant) {
      this.getUserInfoByDistinguishedName(this.state.results.C_assistant, false);
    }
  }

  getCountriesByUserBu(update = false) {
    let countries = [""];
    if (!update && this.state.C_countryname) {
      countries.push(this.state.C_countryname);
    }

    this.state.addresses.forEach(address => {
      if (countries.indexOf(address.C_countryname) === -1) {
        countries.push(address.C_countryname);
      }
    });

    this.setState({
      countries: countries.sort()
    });
  }

  getCitiesByCountry(update = false) {
    let cities = [""];
    if (update) {
      this.setState({
        zipCode: "",
        streetAddress: "",
        C_houseIdentifier: ""
      });
    }
    if (!update && this.state.city) {
      cities.push(this.state.city);
    }
    if (!this.state.C_countryname) {
      this.setState({
        cities: cities
      });
      return;
    }
    let filteredAddresses = this.state.addresses.filter(x => x.C_countryname === this.state.C_countryname);
    filteredAddresses.forEach(address => {
      if (cities.indexOf(address.city) === -1) {
        cities.push(address.city);
      }
    });

    this.setState({
      cities: cities.sort()
    });
  }

  getstreetAddressesByCity(update = false) {
    let streetAddresses = [""];
    if (update) {
      this.setState({
        C_houseIdentifier: ""
      });
    }
    if (!update && this.state.streetAddress) {
      streetAddresses.push(this.state.streetAddress);
    }
    if (!this.state.city || !this.state.C_countryname) {
      this.setState({
        streetAddresses: streetAddresses
      });
      return;
    }
    let filteredAddresses = this.state.addresses.filter(x =>
      x.C_countryname === this.state.C_countryname && x.city === this.state.city
    );
    filteredAddresses.forEach(address => {
      if (streetAddresses.indexOf(address.streetAddress) === -1) {
        streetAddresses.push(address.streetAddress);
      }
    });

    this.setState({
      streetAddresses: streetAddresses.sort()
    });
  }

  getZipCodeByCity() {
    let index = this.state.addresses.findIndex(x =>
      x.C_countryname === this.state.C_countryname &&
      x.city === this.state.city
    );
    if (index !== -1) {
      this.setState({
        zipCode: this.state.addresses[index].zipCode
      });
    }
    else {
      this.setState({
        zipCode: ""
      });

    }
  }

  getPhysicalDeliveryOfficeNamesByAddress(update = false) {
    let physicalDeliveryOfficeNames = [""];
    if (!update && this.state.C_houseIdentifier) {
      physicalDeliveryOfficeNames.push(this.state.C_houseIdentifier);
    }
    let filteredAddresses = this.state.addresses.filter(x => {
      if (this.state.C_countryname && this.state.city && this.state.streetAddress) {
        return x.C_countryname === this.state.C_countryname && x.city === this.state.city && x.streetAddress === this.state.streetAddress
      } else {
        return x.C_countryname === this.state.C_countryname && x.city === this.state.city
      }
    }
    );
    if (filteredAddresses.length > 0) {
      filteredAddresses.forEach(address => {
        if (physicalDeliveryOfficeNames.indexOf(address.C_houseIdentifier) === -1) {
          physicalDeliveryOfficeNames.push(address.C_houseIdentifier);
        };
      });
    }

    this.setState({
      physicalDeliveryOfficeNames: physicalDeliveryOfficeNames.sort()
    });
  }

  getAddress() {
    let filteredAddresses = this.state.addresses.filter(x => x.C_houseIdentifier == this.state.C_houseIdentifier);
    if (filteredAddresses.length === 1) {
      this.setState({
        city: filteredAddresses[0].city,
        C_countryname: filteredAddresses[0].C_countryname,
        zipCode: filteredAddresses[0].zipCode,
        streetAddress: filteredAddresses[0].streetAddress,
      });
    }
  }

  isFromGRDF(user) {
    if (user && user.C_msDSCloudExtensionAttribute2 === "ORG0212") {
      return true;
    } else {
      return false;
    }
  };

  async getpublicprofile(accepted) {
    if (accepted) {
      try {
        const accessToken = this.props.authState.accessToken.accessToken;
        const response = await fetch(config.env.resourceServer.publicProfile, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
        });

        const data = await response.json();
        this.setState({ showQRCode: true, publicHash: data.publicHash });
      } catch (err) {
        console.error(err);
      }
      this.closeModal();
      return;
    }
    if (this.state.results && !this.state.publicHash.includes("[disabled]")) {
      this.setState({ showQRCode: !this.state.showQRCode });
    } else if (this.state.results) {
      this.setState({ modalIsOpen: true });
    }
  }

  async switchpublicprofile() {
    if (this.state.results && !this.state.publicHash.includes("[disabled]")) {
      const accessToken = this.props.authState.accessToken.accessToken;
      const response = await fetch(config.env.resourceServer.publicProfile, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
      });

      const data = await response.json();
      this.setState({ publicHash: data.publicHash });
    } else {
      this.getpublicprofile();
    }
  }

  render() {
    return (
      <div>
        {!this.state.ready && <div className="probootstrap-loader"></div>}
        {this.state.ready && !this.state.failed && this.state.isAuthenticated &&
          <div>
            <section className="probootstrap-slider flexslider2 page-inner">
              <div className="overlay engiegradient-background"></div>
              <div className="probootstrap-wrap-banner">
                <div className="container">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="page-title">
                        <div className="probootstrap-breadcrumbs">
                          <a href="/">Home</a><span>Profile</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div id="main-wrapper">
              <Modal
                isOpen={this.state.modalIsOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeModal}
                style={customStyles}
                contentLabel="Legal"
              >

                <PublicProfileDisclaimer />
                <div className='publicprofilebutton'>
                  <button onClick={() => this.getpublicprofile(true)}>Accept</button>
                  <button onClick={this.closeModal}>Decline</button>
                </div>
              </Modal>
              <form>
                <div className="columns-block container">
                  <div className="left-col-block blocks">
                    <header className="header theiaStickySidebar">
                      {this.state.showQRCode &&
                        <div className="qrcode">
                          <QRCode value={config.env.publicVCard + "/callback/" + this.state.publicHash} />
                        </div>
                      }
                      {!this.state.showQRCode &&
                        <div className="profile-img">
                          <div className="profile-img-group mx-auto">
                            {this.state.userPhoto &&
                              <img src={this.state.userPhoto} id={this.state.results.login} className="img-responsive" alt="" />
                            }
                            {!this.state.userPhoto &&
                              <img src="img/default_profile_photo.png" id={this.state.results.login} className="img-responsive" alt="" />
                            }

                            <input ref={(ref) => this.upload = ref} type="file" accept="image/jpeg,image/jpg" style={{ display: "none" }} onChange={this.onChangeFile.bind(this)} />
                            {(this.state.userinfo.preferred_username === this.state.results.login) &&
                              <span className="invisibleButton change-photo-button" title="Update photo" alt="Update photo" onClick={() => { this.upload.click() }}><i className="ms-Icon ms-Icon--Camera" aria-hidden="true"></i></span>
                            }
                          </div>
                        </div>
                      }
                      <div className="content">
                        {this.state.isEditable && !this.state.showQRCode &&
                          <div className="edit-profile-btn" onClick={() => { this.switchEditMode() }}>
                            <i className="ms-Icon ms-Icon--EditContact" aria-hidden="true"></i>
                          </div>
                        }
                        <h1>{this.state.results.firstName} {this.state.results.lastName ? this.state.results.lastName.toUpperCase() : ""}</h1>
                        {this.state.results.title ? this.state.results.title.toUpperCase() : ''}<br />
                        {this.state.results.gbuName ? this.state.results.gbuName : ""} {this.state.results.gbuName && this.state.results.gbuName && this.state.results.C_countryname ? "-" : ""} {this.state.results.C_countryname}
                        <ul className="social-icon">
                          <li><span><a href={"https://teams.microsoft.com/l/chat/0/0?users=" + this.state.results.email} target="_blank" rel="noopener noreferrer"><i className="ms-Icon ms-Icon--TeamsLogo" style={{ color: "rgb(85, 88, 175)" }}></i></a></span></li>
                          <li><span><a href={"mailto:" + this.state.results.email}><i className="ms-Icon ms-Icon--OutlookLogo"></i></a></span></li>
                          {!this.state.isCurrentUser &&
                            <li><span className="invisibleButton" title="Export contact" alt="Export contact" onClick={download.bind(this, this.state.results)}><i className="ms-Icon ms-Icon--ContactLink" aria-hidden="true"></i></span></li>
                          }
                          {this.state.isCurrentUser &&
                            <li><span className="invisibleButton" title="Get public profile" alt="Get public profile" onClick={() => this.getpublicprofile()}><img className="ms-Icon qrcodeicon" src="img/QRCode.png" alt="qrcode" /></span></li>
                          }
                          {this.state.results && this.isFromGRDF(this.state.results) === false && this.state.results.userType === 'I' &&
                            <li><span><a href={'/orgchart?' + this.state.results.login}><i className="ms-Icon ms-Icon--Org" style={{ color: "#333333" }}></i></a></span></li>
                          }
                        </ul>
                      </div>
                      <div className="content">
                        <a href={"mailto:" + this.state.results.email}>{this.state.results.email ? this.state.results.email.toLowerCase() : ""}</a>
                      </div>
                      <div className="content">
                        {((this.state.editableFields.indexOf("primaryPhone") === -1 || this.state.isEditMode === false) && this.state.results.primaryPhone) &&
                          <span><i className="ms-Icon ms-Icon--Phone"></i> <a href={"tel:" + this.state.results.primaryPhone}>{this.state.results.primaryPhone}</a></span>
                        }
                        {this.state.editableFields.indexOf("primaryPhone") !== -1 && this.state.isEditMode === true &&
                          <label className="profile-form">
                            <FormattedMessage id="field.primaryPhone" defaultMessage="Primary phone" /> :
                            <input className={this.state.formError["primaryPhone"] ? "form-control input-error" : "form-control"} type="text" name="primaryPhone" placeholder={"+XXX..."} defaultValue={this.state.primaryPhone ? this.state.primaryPhone : this.state.results.primaryPhone} onChange={this.handleInputChange} />
                            {this.state.formError["primaryPhone"] &&
                              <small className="input-error"><FormattedMessage id={this.state.formError["primaryPhone"]} defaultMessage="Bad value" /></small>
                            }
                          </label>
                        }
                      </div>
                      <div className="content">
                        {((this.state.editableFields.indexOf("mobilePhone") === -1 || this.state.isEditMode === false) && this.state.results.mobilePhone) &&
                          <span><i className="ms-Icon ms-Icon--CellPhone"></i> <a href={"tel:" + this.state.results.mobilePhone}>{this.state.results.mobilePhone}</a></span>
                        }
                        {this.state.editableFields.indexOf("mobilePhone") !== -1 && this.state.isEditMode === true &&
                          <label className="profile-form">
                            <FormattedMessage id="field.mobilePhone" defaultMessage="Mobile phone" /> :
                            <input className={this.state.formError["mobilePhone"] ? "form-control input-error" : "form-control"} type="text" name="mobilePhone" placeholder={"+XXX..."} defaultValue={this.state.mobilePhone ? this.state.mobilePhone : this.state.results.mobilePhone} onChange={this.handleInputChange} />
                            {this.state.formError["mobilePhone"] &&
                              <small className="input-error"><FormattedMessage id={this.state.formError["mobilePhone"]} defaultMessage="Bad value" /></small>
                            }
                          </label>
                        }
                      </div>
                      <div className="content">
                        {((this.state.editableFields.indexOf("C_ipPhone") === -1 || this.state.isEditMode === false) && this.state.results.C_ipPhone) &&
                          <span>IP <i className="ms-Icon ms-Icon--Phone"></i> <a href={"tel:" + this.state.results.C_ipPhone}>{this.state.results.C_ipPhone}</a></span>
                        }
                        {this.state.editableFields.indexOf("C_ipPhone") !== -1 && this.state.isEditMode === true &&
                          <label className="profile-form">
                            <FormattedMessage id="field.C_ipPhone" defaultMessage="IP phone" /> :
                            <input className={this.state.formError["C_ipPhone"] ? "form-control input-error" : "form-control"} type="text" name="C_ipPhone" defaultValue={this.state.C_ipPhone ? this.state.C_ipPhone : this.state.results.C_ipPhone} onChange={this.handleInputChange} />
                            {this.state.formError["C_ipPhone"] &&
                              <small className="input-error"><FormattedMessage id={this.state.formError["C_ipPhone"]} defaultMessage="Bad value" /></small>
                            }
                          </label>
                        }
                      </div>
                      <div className="content">
                        {((this.state.editableFields.indexOf("C_facsimileTelephoneNumber") === -1 || this.state.isEditMode === false) && this.state.results.C_facsimileTelephoneNumber) &&
                          <span><i className="ms-Icon ms-Icon--Fax"></i> <a href={"tel:" + this.state.results.C_facsimileTelephoneNumber}>{this.state.results.C_facsimileTelephoneNumber}</a></span>
                        }
                        {this.state.editableFields.indexOf("C_facsimileTelephoneNumber") !== -1 && this.state.isEditMode === true &&
                          <label className="profile-form">
                            <FormattedMessage id="field.C_facsimileTelephoneNumber" defaultMessage="Fax" /> :
                            <input className={this.state.formError["C_facsimileTelephoneNumber"] ? "form-control input-error" : "form-control"} type="text" name="C_facsimileTelephoneNumber" placeholder={"+XXX..."} defaultValue={this.state.C_facsimileTelephoneNumber ? this.state.C_facsimileTelephoneNumber : this.state.results.C_facsimileTelephoneNumber} onChange={this.handleInputChange} />
                            {this.state.formError["C_facsimileTelephoneNumber"] &&
                              <small className="input-error"><FormattedMessage id={this.state.formError["C_facsimileTelephoneNumber"]} defaultMessage="Bad value" /></small>
                            }
                          </label>
                        }
                      </div>
                      {(this.isFromGRDF(this.state.results) === false && this.state.results.userType === 'I') &&
                        <div key="infoprofile-manager" className="content"><FormattedMessage id="field.manager" defaultMessage="Manager" /> :
                          <a href={'/Profile?' + this.state.results.managerUserId}> {this.state.results.managerUserName}</a>
                        </div>
                      }
                      {(this.isFromGRDF(this.state.results) === false && this.state.results.userType === 'E') &&
                        <div key="infoprofile-headOfMission" className="content"><FormattedMessage id="field.headOfMission" defaultMessage="Head of Mission" /> :
                          <a href={'/Profile?' + this.state.results.managerUserId}> {this.state.results.managerUserName}</a>
                        </div>
                      }
                      {(this.state.editableFields.indexOf("C_assistant") === -1 || this.state.isEditMode === false) &&
                        <div key="infoprofile-C_assistant" className="content"><FormattedMessage id="field.assistant" defaultMessage="Assistant" /> :
                          <a href={'/Profile?' + this.state.results.assistantUserId}> {this.state.results.assistantUserName}</a>
                        </div>
                      }
                      {this.state.editableFields.indexOf("C_assistant") !== -1 && this.state.isEditMode === true &&
                        <label className="profile-form">
                          <FormattedMessage id="field.assistant" defaultMessage="Assistant" /> :
                          <input className="form-control" type="text" name="assistantUserName" value={this.state.assistantUserName} onChange={this.handleInputChange} autoComplete="off" />
                          {this.state.intuitiveResults.length > 0 &&
                            <div className={this.state.showIntuitiveResults ? "dropdown-menu intuitive-assistant-menu show" : "dropdown-menu"}>
                              {this.state.intuitiveResults.map((element) => {
                                var a = this.state.intuitiveResults.indexOf(element);
                                return <div ref={this.setWrapperRef} key={a} onClick={() => { this.handleInputChange({ target: { type: 'text', value: element.firstName + " " + element.lastName.toUpperCase(), distinguishedName: element.C_distinguishedName, name: 'assistantUserName' } }, true) }} className="dropdown-item intuitive-result">
                                  <div className="profile-img intuitive-img edit-assistant">
                                    <img id={"intuitive-" + element.login} src="img/default_profile_photo.png" className="img-responsive" alt="Free HTML5 Template by uicookies.com" />
                                  </div>
                                  <div className="intuitive-description">
                                    {element.firstName || element.lastName ? element.firstName + " " + element.lastName.toUpperCase() : "UNKNWOWN USER"}
                                    <span className="intuitive-mail">
                                      {element.email.toLowerCase()}</span>
                                  </div>
                                </div>
                              })}
                            </div>
                          }
                        </label>
                      }
                      {((this.state.editableFields.indexOf("linkedInURI") === -1 || this.state.isEditMode === false) && this.state.results.linkedInURI) &&
                        <div key="infoprofile-linkedInURI" className="content">
                          <a href={this.state.results.linkedInURI}><FormattedMessage id="field.linkedInProfile" defaultMessage="LinkedIn profile" /></a>
                        </div>
                      }
                      {this.state.editableFields.indexOf("linkedInURI") !== -1 && this.state.isEditMode === true &&
                        <label className="profile-form">
                          <FormattedMessage id="field.linkedInURI" defaultMessage="LinkedIn" /> :
                          <input className={this.state.formError["linkedInURI"] ? "form-control input-error" : "form-control"} type="text" name="linkedInURI" placeholder={"https://linkedin.com/..."} defaultValue={this.state.linkedInURI ? this.state.linkedInURI : this.state.results.linkedInURI} onChange={this.handleInputChange} />
                        </label>
                      }
                      {((this.state.editableFields.indexOf("xURI") === -1 || this.state.isEditMode === false) && this.state.results.xURI) &&
                        <div key="infoprofile-xURI" className="content">
                          <a href={this.state.results.xURI}><FormattedMessage id="field.xProfile" defaultMessage="Twitter profile" /></a>
                        </div>
                      }
                      {this.state.editableFields.indexOf("xURI") !== -1 && this.state.isEditMode === true &&
                        <label className="profile-form">
                          <FormattedMessage id="field.xURI" defaultMessage="X" /> :
                          <input className={this.state.formError["xURI"] ? "form-control input-error" : "form-control"} type="text" name="xURI" placeholder={"https://twitter.com/..."} defaultValue={this.state.xURI ? this.state.xURI : this.state.results.xURI} onChange={this.handleInputChange} />
                        </label>
                      }
                    </header>
                  </div>
                  <div className="right-col-block blocks">
                    <div className="theiaStickySidebar">
                      <section className="expertise-wrapper section-wrapper gray-bg">
                        <div className="container-fluid">
                          <div className="row">
                            <div key="infoprofile-division" className="col-md-6">
                              <div className="expertise-item">
                                <strong>GBU</strong>
                                <p>
                                  {this.state.results.gbuName}
                                </p>
                              </div>
                            </div>
                            <div key="infoprofile-organization" className="col-md-6">
                              <div className="expertise-item">
                                <strong><FormattedMessage id="field.company" defaultMessage="Company" /></strong>
                                <p>
                                  {this.state.results.organization}
                                </p>
                              </div>
                            </div>
                            <div key="infoprofile-userType" className="col-md-6">
                              <div className="expertise-item">
                                <strong><FormattedMessage id="field.employeetype" defaultMessage="Employee Type" /></strong>
                                <p>
                                  {this.state.results.userType ? this.state.results.userType === "I" || this.state.results.userType === "Agent" ? "Internal" : "External" : ""}
                                </p>
                              </div>
                            </div>
                            <div key="infoprofile-C_groupIdentifier" className="col-md-6">
                              <div className="expertise-item">
                                <strong><FormattedMessage id="field.groupid" defaultMessage="Group ID" /></strong>
                                <p>
                                  {this.state.results.C_groupIdentifier}
                                </p>
                              </div>
                            </div>
                            <div key="infoprofile-C_businessCategory" className="col-md-6">
                              <div className="expertise-item">
                                <strong><FormattedMessage id="field.jobfamily" defaultMessage="Job family" /></strong>
                                <p>
                                  {this.state.results.C_businessCategory &&
                                    <FormattedMessage id={"field.jobfamily." + this.state.results.C_businessCategory} defaultMessage={this.state.results.C_businessCategory} />
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section className="expertise-wrapper section-wrapper gray-bg">
                        <div className="container-fluid">
                          <div className="row">
                            <div key="infoprofile-streetAddress" className="col-md-6">
                              <div className="expertise-item">
                                {this.state.isEditMode === false &&
                                  <strong><FormattedMessage id="field.address" defaultMessage="Address" /></strong>
                                }
                                {(this.state.editableFields.indexOf("C_countryname") === -1 || this.state.isEditMode === false) &&
                                  <div>
                                    <div>
                                      {this.state.results.streetAddress}
                                    </div>
                                    <div>
                                      {this.state.results.zipCode} {this.state.results.city}
                                    </div>
                                    <div>
                                      {this.state.results.C_countryname}
                                    </div>
                                  </div>
                                }

                                <p>
                                  {this.state.editableFields.indexOf("C_countryname") !== -1 && this.state.isEditMode === true &&
                                    <label className="profile-form">
                                      <FormattedMessage id="field.country" defaultMessage="Country" /> :
                                      <select className={this.state.formError["C_countryname"] ? "form-control input-error" : "form-control"} onChange={this.handleInputChange} name="C_countryname" value={this.state.C_countryname}>
                                        {this.state.countries.map((element) => {
                                          return <option key={element} value={element}>{element}</option>;
                                        })}
                                      </select>
                                      {this.state.formError["C_countryname"] &&
                                        <small className="input-error"><FormattedMessage id={this.state.formError["C_countryname"]} defaultMessage="Bad value" /></small>
                                      }
                                    </label>
                                  }
                                  {this.state.editableFields.indexOf("city") !== -1 && this.state.isEditMode === true &&
                                    <label className="profile-form">
                                      <FormattedMessage id="field.city" defaultMessage="City" /> :
                                      <select className={this.state.formError["city"] ? "form-control input-error" : "form-control"} type="text" name="city" value={this.state.city} onChange={this.handleInputChange} >
                                        {this.state.cities.map((element) => {
                                          return <option key={element} value={element}>{element}</option>;
                                        })}
                                      </select>
                                      {this.state.formError["city"] &&
                                        <small className="input-error"><FormattedMessage id={this.state.formError["city"]} defaultMessage="Bad value" /></small>
                                      }
                                    </label>
                                  }
                                  {this.state.editableFields.indexOf("zipCode") !== -1 && this.state.isEditMode === true &&
                                    <label className="profile-form">
                                      <FormattedMessage id="field.zipCode" defaultMessage="zipCode" /> :
                                      <input className={this.state.formError["zipCode"] ? "form-control input-error" : "form-control"} type="text" name="zipCode" value={this.state.zipCode} onChange={this.handleInputChange} disabled />
                                      {this.state.formError["zipCode"] &&
                                        <small className="input-error"><FormattedMessage id={this.state.formError["zipCode"]} defaultMessage="Bad value" /></small>
                                      }
                                    </label>
                                  }
                                  {this.state.editableFields.indexOf("streetAddress") !== -1 && this.state.isEditMode === true &&
                                    <label className="profile-form">
                                      <FormattedMessage id="field.streetAddress" defaultMessage="Street address" /> :
                                      <select className={this.state.formError["streetAddress"] ? "form-control input-error" : "form-control"} type="text" name="streetAddress" value={this.state.streetAddress} onChange={this.handleInputChange} >
                                        {this.state.streetAddresses.map((element) => {
                                          return <option key={element} value={element}>{element}</option>;
                                        })}
                                      </select>
                                      {this.state.formError["streetAddress"] &&
                                        <small className="input-error"><FormattedMessage id={this.state.formError["streetAddress"]} defaultMessage="Bad value" /></small>
                                      }
                                    </label>
                                  }
                                </p>
                              </div>
                            </div>
                            <div key="infoprofile-C_houseIdentifier" className="col-md-6">
                              <div className="expertise-item">
                                <strong><FormattedMessage id="field.site" defaultMessage="Site name" /></strong>
                                <p>
                                  {(this.state.editableFields.indexOf("C_houseIdentifier") === -1 || this.state.isEditMode === false) &&
                                    this.state.results.C_houseIdentifier
                                  }
                                  {this.state.editableFields.indexOf("C_houseIdentifier") !== -1 && this.state.isEditMode === true &&
                                    <label className="profile-form">
                                      <small className="display-info"><FormattedMessage id="displayinfo.siteName" defaultMessage="Please update country and city to select the site" /></small><br />
                                      <select className={this.state.formError["C_houseIdentifier"] ? "form-control input-error" : "form-control"} type="text" name="C_houseIdentifier" value={this.state.C_houseIdentifier} onChange={this.handleInputChange}>
                                        {this.state.physicalDeliveryOfficeNames.map((element) => {
                                          return <option key={element} value={element}>{element}</option>;
                                        })}
                                        {this.state.formError["C_houseIdentifier"] &&
                                          <small className="input-error"><FormattedMessage id={this.state.formError["C_houseIdentifier"]} defaultMessage="Bad value" /></small>
                                        }
                                      </select>
                                    </label>
                                  }
                                </p>
                              </div>
                              <div className="expertise-item">
                                <strong><FormattedMessage id="field.location" defaultMessage="My location" /></strong>
                                <p>
                                  {(this.state.editableFields.indexOf("C_physicalDeliveryOfficeName") === -1 || this.state.isEditMode === false) &&
                                    this.state.results.C_physicalDeliveryOfficeName
                                  }
                                  {this.state.editableFields.indexOf("C_physicalDeliveryOfficeName") !== -1 && this.state.isEditMode === true &&
                                    <label className="profile-form">
                                      <input className={this.state.formError["C_physicalDeliveryOfficeName"] ? "form-control input-error" : "form-control"} type="text" name="C_physicalDeliveryOfficeName" value={this.state.C_physicalDeliveryOfficeName} onChange={this.handleInputChange} />
                                    </label>
                                  }
                                </p>
                              </div>
                              <div className="expertise-item">
                                <strong><FormattedMessage id="field.workinglanguage" defaultMessage="Working Language" /></strong>
                                <p>
                                  {(this.state.editableFields.indexOf("preferredLanguage") === -1 || this.state.isEditMode === false) &&
                                    this.state.results.preferredLanguage ? <FormattedMessage id={"field.workinglanguage." + this.state.results.preferredLanguage} defaultMessage={this.state.results.preferredLanguage} /> : ""
                                  }
                                  {this.state.editableFields.indexOf("preferredLanguage") !== -1 && this.state.isEditMode === true &&
                                    <label className="profile-form">
                                      <select className={this.state.formError["preferredLanguage"] ? "form-control input-error" : "form-control"} onChange={this.handleInputChange} name="preferredLanguage" defaultValue={this.state.preferredLanguage ? this.state.preferredLanguage : this.state.results.preferredLanguage ? this.state.results.preferredLanguage : ""}>
                                        {this.state.formError["preferredLanguage"] &&
                                          <option value={this.state.preferredLanguage}>{this.state.preferredLanguage}</option>
                                        }
                                        <option value=''></option>
                                        {this.state.enum.Languages.map((element) => {
                                          return <FormattedMessage id={"field.workinglanguageselection." + element} defaultMessage={element}>{(message) => <option key={element} value={element}>{message}</option>}</FormattedMessage>;
                                        })}
                                      </select>
                                      {this.state.formError["preferredLanguage"] &&
                                        <small className="input-error"><FormattedMessage id={this.state.formError["preferredLanguage"]} defaultMessage="Bad value" /></small>
                                      }
                                    </label>
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {this.state.isEditMode === true &&
                          <div className="save-profile">
                            <span className="btn btn-success btn-success-engie cancel" type="cancel" onClick={() => this.switchEditMode()}><FormattedMessage id="button.cancel" defaultMessage="Cancel" /></span>
                            {this.state.isFormError === true &&
                              <span className="btn btn-success btn-success-engie save disabled" type="submit"><FormattedMessage id="button.submit" defaultMessage="Submit" /></span>
                            }
                            {this.state.isFormError === false &&
                              <span className="btn btn-success btn-success-engie save" type="submit" onClick={() => this.handleSubmit()}><FormattedMessage id="button.submit" defaultMessage="Submit" /></span>
                            }
                          </div>
                        }
                      </section>
                      {this.state.isCurrentUser === true &&
                        <section className="expertise-wrapper section-wrapper gray-bg">
                          <div className="container-fluid">
                            <div className="row">
                              <div className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" id="customSwitch1" checked={this.state?.publicHash && !this.state.publicHash.includes("[disabled]") ? true : false} onChange={() => this.switchpublicprofile()} />
                                <label className="custom-control-label" htmlFor="customSwitch1"><FormattedMessage id="profile.public" defaultMessage="Activate my online business card" /></label>
                              </div>
                            </div>
                          </div>
                        </section>
                      }
                      {this.state.isEditMode === false && this.state.isEditable === false && this.state.isCurrentUser &&
                        <section className="expertise-wrapper section-wrapper gray-bg">
                          <div className="container-fluid">
                            <div className="row">
                              <div>
                                <div className="expertise-item">
                                  <p>
                                    <FormattedMessage id="profile.info" defaultMessage="If your displayed data does not seem correct, do not hesitate to contact your IT support." />
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      }
                    </div>
                  </div>
                </div>
              </form>
            </div >
          </div >
        }
        {
          this.state.ready && this.state.failed &&
          <div>
            <section className="probootstrap-slider flexslider2 page-inner">
              <div className="overlay"></div>
              <div className="probootstrap-wrap-banner">
                <div className="container">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="page-title">
                        <div className="probootstrap-breadcrumbs">
                          <a href="/">Home</a><span>Profile</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            Impossible to get your profile.
          </div>
        }
      </div >
    );
  }
});
