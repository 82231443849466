const jobfamilies = [
    "AD",
    "AU",
    "BD",
    "CO",
    "EN",
    "EP",
    "FI",
    "HR",
    "IC",
    "IN",
    "IT",
    "LT",
    "MG",
    "MK",
    "MT",
    "OP",
    "PR",
    "QH",
    "RD",
    "SC",
    "ST",
    "TP",
    "TR"
];



exports.jobfamilies = jobfamilies;