const languages = [
    "fr-BE",
    "fr-FR",
    "en-GB",
    "en-US",
    "nl-NL",
    "es-CL",
    "es-ES",
    "es-MX",
    "de-DE",
    "it-IT",
    "pl-PL",
    "pt-BR",
    "pt-PT",
    "ro-RO",
    "sv-SE",
    "ko-KR",
    "zh-CN",
    "th-TH",
    "cs-CS",
    "id-ID",
    "tr-TR",
    "ru-RU",
    "hu-HU"
]

exports.languages = languages;