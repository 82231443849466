const BUs = [
  {
    id: "BU01",
    name: "France Renewables",
    shortName: "France Renewables"
  },
  {
    id: "BU02",
    name: "France BtoB",
    shortName: "BtoB"
  },
  {
    id: "BU03",
    name: "France BtoC",
    shortName: "BtoC"
  },
  {
    id: "BU04",
    name: "France Networks",
    shortName: "France Networks"
  },
  {
    id: "BU05",
    name: "Generation Europe",
    shortName: "Generation Europe"
  },
  {
    id: "BU06",
    name: "Benelux",
    shortName: "Benelux"
  },
  {
    id: "BU07",
    name: "UK",
    shortName: "UK"
  },
  {
    id: "BU08",
    name: "North South and Eastern Europe",
    shortName: "NECST"
  },
  {
    id: "BU09",
    name: "North America",
    shortName: "North America"
  },
  {
    id: "BU10",
    name: "Latin America",
    shortName: "Latin America"
  },
  {
    id: "BU11",
    name: "Brazil",
    shortName: "Brazil"
  },
  {
    id: "BU12",
    name: "Africa",
    shortName: "Africa"
  },
  {
    id: "BU13",
    name: "China",
    shortName: "China"
  },
  {
    id: "BU14",
    name: "Middle East Southern and Central Asia and Turkey",
    shortName: "MESCAT"
  },
  {
    id: "BU15",
    name: "Asia Pacific",
    shortName: "APAC"
  },
  {
    id: "BU16",
    name: "GRTgaz",
    shortName: "GRTgaz"
  },
  {
    id: "BU17",
    name: "GRDF",
    shortName: "GRDF"
  },
  {
    id: "BU18",
    name: "Elengy",
    shortName: "Elengy"
  },
  {
    id: "BU19",
    name: "Storengy",
    shortName: "Storengy"
  },
  {
    id: "BU20",
    name: "Exploration & Production International",
    shortName: "EPI"
  },
  {
    id: "BU21",
    name: "LNG",
    shortName: "LNG"
  },
  {
    id: "BU22",
    name: "Global Energy Management",
    shortName: "GEM"
  },
  {
    id: "BU23",
    name: "Tractebel Engineering",
    shortName: "Tractebel"
  },
  {
    id: "BU24",
    name: "GTT",
    shortName: "GTT"
  },
  {
    id: "BU25",
    name: "Corporate",
    shortName: "Corporate"
  },
  {
    id: "BU26",
    name: "Global Business Support",
    shortName: "GBS"
  },
  {
    id: "BU27",
    name: "Hydrogen",
    shortName: "Hydrogen"
  },
  {
    id: "BU28",
    name: "Nuclear",
    shortName: "Nuclear"
  },
  {
    id: "BU29",
    name: "Impact",
    shortName: "Impact"
  }
];

exports.BUs = BUs;
exports.BUsSortedByName = BUs.sort((a, b) => { return a.name < b.name ? -1 : a.name > b.name ? 1 : 0 });
exports.BUsSortedByShortName = BUs.sort((a, b) => { return a.shortName < b.shortName ? -1 : a.shortName > b.shortName ? 1 : 0 });