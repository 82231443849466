const devServerBaseUrl = 'https://ppr-directory.myengie.com/api';
const dev = {
  oidc: {
    clientId: '0oa2xl7nh0yeG4hDr0i7',
    issuer: 'https://engie-pre.okta-emea.com/oauth2/aus2xl6f0wnxYuK0E0i7',
    redirectUri: window.location.origin + '/implicit/callback',
    scopes: ['openid', 'profile', 'email'],
  },
  resourceServer: {
    publicProfile: `${devServerBaseUrl}/public-profile`,
    getOktaUsersUrl: `${devServerBaseUrl}/get-okta-users`,
    getOktaProfileUrl: `${devServerBaseUrl}/get-user-profile`,
    getOktaUserPhotoUrl: `${devServerBaseUrl}/get-user-photo`,
    updateOktaUserPhotoUrl: `${devServerBaseUrl}/update-user-photo`,
    updateUserProfile: `${devServerBaseUrl}/update-user-profile`,
    addresses: `${devServerBaseUrl}/addresses`,
    selfServiceDomains: `${devServerBaseUrl}/selfservicedomains`,
    selfServiceOrganizations: `${devServerBaseUrl}/selfserviceorganizations`,
    getManager: `${devServerBaseUrl}/get-users-by-manager`,
    getGBUsUrl: `${devServerBaseUrl}/gbus`,
    admin: {
      buAddresses: `${devServerBaseUrl}/admin/buaddresses`,
      selfServiceDomains: `${devServerBaseUrl}/admin/selfservicedomains`,
      selfServiceOrganizations: `${devServerBaseUrl}/admin/selfserviceorganizations`,
      users: `${devServerBaseUrl}/admin/users`,
    },
  },
  domain: "ensemble.infra.com",
  REACT_APP_GOOGLE_KEY: "AIzaSyBf11iwE5D5CuBchJzazioxAMIeBc4sR4g",
  publicVCard: "https://ppr-mycard.myengie.com"
}

const preprodServerBaseUrl = 'https://ppr-directory.myengie.com/api';
const preprod = {
  oidc: {
    clientId: '0oa2xl7nh0yeG4hDr0i7',
    issuer: 'https://engie-pre.okta-emea.com/oauth2/aus2xl6f0wnxYuK0E0i7',
    redirectUri: window.location.origin + '/implicit/callback',
    scopes: ['openid', 'profile', 'email'],
  },
  resourceServer: {
    publicProfile: `${preprodServerBaseUrl}/public-profile`,
    getOktaUsersUrl: `${preprodServerBaseUrl}/get-okta-users`,
    getOktaProfileUrl: `${preprodServerBaseUrl}/get-user-profile`,
    getOktaUserPhotoUrl: `${preprodServerBaseUrl}/get-user-photo`,
    updateOktaUserPhotoUrl: `${preprodServerBaseUrl}/update-user-photo`,
    updateUserProfile: `${preprodServerBaseUrl}/update-user-profile`,
    addresses: `${preprodServerBaseUrl}/addresses`,
    selfServiceDomains: `${preprodServerBaseUrl}/selfservicedomains`,
    selfServiceOrganizations: `${preprodServerBaseUrl}/selfserviceorganizations`,
    getManager: `${preprodServerBaseUrl}/get-users-by-manager`,
    getGBUsUrl: `${preprodServerBaseUrl}/gbus`,
    admin: {
      buAddresses: `${preprodServerBaseUrl}/admin/buaddresses`,
      selfServiceDomains: `${preprodServerBaseUrl}/admin/selfservicedomains`,
      selfServiceOrganizations: `${preprodServerBaseUrl}/admin/selfserviceorganizations`,
      users: `${preprodServerBaseUrl}/admin/users`,
    },
  },
  domain: "ensemble.infra.com",
  REACT_APP_GOOGLE_KEY: "AIzaSyBf11iwE5D5CuBchJzazioxAMIeBc4sR4g",
  publicVCard: "https://ppr-mycard.myengie.com"
}

const prodServerBaseUrl = 'https://directory.myengie.com/api';
const prod = {
  oidc: {
    clientId: '0oa2sk71wwej70Adc0i7',
    issuer: 'https://engie.okta-emea.com/oauth2/aus2sk70kwxquoaM70i7',
    redirectUri: window.location.origin + '/implicit/callback',
    scopes: ['openid', 'profile', 'email'],
  },
  resourceServer: {
    publicProfile: `${prodServerBaseUrl}/public-profile`,
    getOktaUsersUrl: `${prodServerBaseUrl}/get-okta-users`,
    getOktaProfileUrl: `${prodServerBaseUrl}/get-user-profile`,
    getOktaUserPhotoUrl: `${prodServerBaseUrl}/get-user-photo`,
    updateOktaUserPhotoUrl: `${prodServerBaseUrl}/update-user-photo`,
    updateUserProfile: `${prodServerBaseUrl}/update-user-profile`,
    addresses: `${prodServerBaseUrl}/addresses`,
    selfServiceDomains: `${prodServerBaseUrl}/selfservicedomains`,
    selfServiceOrganizations: `${prodServerBaseUrl}/selfserviceorganizations`,
    getManager: `${prodServerBaseUrl}/get-users-by-manager`,
    getGBUsUrl: `${prodServerBaseUrl}/gbus`,
    admin: {
      buAddresses: `${prodServerBaseUrl}/admin/buaddresses`,
      selfServiceDomains: `${prodServerBaseUrl}/admin/selfservicedomains`,
      selfServiceOrganizations: `${prodServerBaseUrl}/admin/selfserviceorganizations`,
      users: `${prodServerBaseUrl}/admin/users`,
    },
  },
  domain: "engie.com",
  REACT_APP_GOOGLE_KEY: "AIzaSyBf11iwE5D5CuBchJzazioxAMIeBc4sR4g",
  PUBLIC_URL: 'https://directory.myengie.com',
  publicVCard: "https://mycard.myengie.com"
}

const env = process.env.REACT_APP_STAGE === 'production' ? prod : process.env.REACT_APP_STAGE === 'preprod' ? preprod : dev;

export default {
  env
};