import { withOktaAuth } from '@okta/okta-react';
import React, { Component } from 'react';

const language = navigator.language.split(/[-_]/)[0];  // language without region code
const supportedLanguages = ['fr', 'es', 'nl', 'it', 'pt', 'en'];
const verifiedLanguage = supportedLanguages.indexOf(language) !== -1 ? language : 'notSupported';

export default withOktaAuth(class PublicProfileDisclaimer extends Component {
    constructor() {
        super();
        this.state = { language: verifiedLanguage };
    }

    render() {
        return (
            <div>
                {this.state.language === "notSupported" &&
                    <div>
                        <h3>Information statement</h3><br />
                        <p>Please note that by activating this feature, the personal data on your business card may be shared with additional recipients. Your personal data are processed in the context of the ENGIE Directory in accordance with the applicable regulations.
                            For more information and to exercise your rights, you can consult the information notice on this processing <a href="https://engie.sharepoint.com/sites/MyEthicsCompliancePrivacy/Gouvernance%20des%20donnes/Forms/AllItems.aspx?id=%2Fsites%2FMyEthicsCompliancePrivacy%2FGouvernance%20des%20donnes%2FIntranet%20%2D%20Mes%20données%20personnelles%20périmètre%20ENGIE%20SA%2FMentions%20d%27informations%2FGroup%20Digital%20%26%20IT%20%28DSI%29%2FENGIE%20Directory%2FIN%20ENGIE%20Directory%2Epdf&viewid=af1068ee%2Dbf9e%2D4baf%2Da479%2D4c3d86190230&parent=%2Fsites%2FMyEthicsCompliancePrivacy%2FGouvernance%20des%20donnes%2FIntranet%20%2D%20Mes%20données%20personnelles%20périmètre%20ENGIE%20SA%2FMentions%20d%27informations%2FGroup%20Digital%20%26%20IT%20%28DSI%29%2FENGIE%20Directory" rel="noopener noreferrer" target="_blanc">here</a>.</p>
                    </div>
                }
                {this.state.language === "en" &&
                    <div>
                        <h3>Information statement</h3><br />
                        <p>Please note that by activating this feature, the personal data on your business card may be shared with additional recipients. Your personal data are processed in the context of the ENGIE Directory in accordance with the applicable regulations.
                            For more information and to exercise your rights, you can consult the information notice on this processing <a href="https://engie.sharepoint.com/sites/MyEthicsCompliancePrivacy/Gouvernance%20des%20donnes/Forms/AllItems.aspx?id=%2Fsites%2FMyEthicsCompliancePrivacy%2FGouvernance%20des%20donnes%2FIntranet%20%2D%20Mes%20données%20personnelles%20périmètre%20ENGIE%20SA%2FMentions%20d%27informations%2FGroup%20Digital%20%26%20IT%20%28DSI%29%2FENGIE%20Directory%2FIN%20ENGIE%20Directory%2Epdf&viewid=af1068ee%2Dbf9e%2D4baf%2Da479%2D4c3d86190230&parent=%2Fsites%2FMyEthicsCompliancePrivacy%2FGouvernance%20des%20donnes%2FIntranet%20%2D%20Mes%20données%20personnelles%20périmètre%20ENGIE%20SA%2FMentions%20d%27informations%2FGroup%20Digital%20%26%20IT%20%28DSI%29%2FENGIE%20Directory" rel="noopener noreferrer" target="_blanc">here</a>.</p>
                    </div>
                }
                {this.state.language === "fr" &&
                    <div>
                        <h3>Mention d'information</h3><br />
                        <p>Attention, en activant cette fonctionnalité les données personnelles figurant sur votre carte de visite pourront être partagées à des destinataires supplémentaires.
                            Vos données personnelles sont traitées dans le cadre de l’outil ENGIE Directory conformément à la réglementation applicable en vigueur.
                            Pour plus d’informations et pour exercer vos droits, vous pouvez consulter la mention d’information relative à ce traitement accessible<a href="https://engie.sharepoint.com/sites/MyEthicsCompliancePrivacy/Gouvernance%20des%20donnes/Forms/AllItems.aspx?id=%2Fsites%2FMyEthicsCompliancePrivacy%2FGouvernance%20des%20donnes%2FIntranet%20%2D%20Mes%20données%20personnelles%20périmètre%20ENGIE%20SA%2FMentions%20d%27informations%2FGroup%20Digital%20%26%20IT%20%28DSI%29%2FENGIE%20Directory%2FMI%20ENGIE%20Directory%2Epdf&viewid=af1068ee%2Dbf9e%2D4baf%2Da479%2D4c3d86190230&parent=%2Fsites%2FMyEthicsCompliancePrivacy%2FGouvernance%20des%20donnes%2FIntranet%20%2D%20Mes%20données%20personnelles%20périmètre%20ENGIE%20SA%2FMentions%20d%27informations%2FGroup%20Digital%20%26%20IT%20%28DSI%29%2FENGIE%20Directory" rel="noopener noreferrer" target="_blanc">ici</a>.</p>
                    </div>
                }
                {this.state.language === "es" &&
                    <div>
                        <h3>Information statement</h3><br />
                        <p>Please note that by activating this feature, the personal data on your business card may be shared with additional recipients. Your personal data are processed in the context of the ENGIE Directory in accordance with the applicable regulations.
                            For more information and to exercise your rights, you can consult the information notice on this processing <a href="https://engie.sharepoint.com/sites/MyEthicsCompliancePrivacy/Gouvernance%20des%20donnes/Forms/AllItems.aspx?id=%2Fsites%2FMyEthicsCompliancePrivacy%2FGouvernance%20des%20donnes%2FIntranet%20%2D%20Mes%20données%20personnelles%20périmètre%20ENGIE%20SA%2FMentions%20d%27informations%2FGroup%20Digital%20%26%20IT%20%28DSI%29%2FENGIE%20Directory%2FIN%20ENGIE%20Directory%2Epdf&viewid=af1068ee%2Dbf9e%2D4baf%2Da479%2D4c3d86190230&parent=%2Fsites%2FMyEthicsCompliancePrivacy%2FGouvernance%20des%20donnes%2FIntranet%20%2D%20Mes%20données%20personnelles%20périmètre%20ENGIE%20SA%2FMentions%20d%27informations%2FGroup%20Digital%20%26%20IT%20%28DSI%29%2FENGIE%20Directory" rel="noopener noreferrer" target="_blanc">here</a>.</p>
                    </div>
                }
                {this.state.language === "nl" &&
                    <div>
                        <h3>Information statement</h3><br />
                        <p>Please note that by activating this feature, the personal data on your business card may be shared with additional recipients. Your personal data are processed in the context of the ENGIE Directory in accordance with the applicable regulations.
                            For more information and to exercise your rights, you can consult the information notice on this processing <a href="https://engie.sharepoint.com/sites/MyEthicsCompliancePrivacy/Gouvernance%20des%20donnes/Forms/AllItems.aspx?id=%2Fsites%2FMyEthicsCompliancePrivacy%2FGouvernance%20des%20donnes%2FIntranet%20%2D%20Mes%20données%20personnelles%20périmètre%20ENGIE%20SA%2FMentions%20d%27informations%2FGroup%20Digital%20%26%20IT%20%28DSI%29%2FENGIE%20Directory%2FIN%20ENGIE%20Directory%2Epdf&viewid=af1068ee%2Dbf9e%2D4baf%2Da479%2D4c3d86190230&parent=%2Fsites%2FMyEthicsCompliancePrivacy%2FGouvernance%20des%20donnes%2FIntranet%20%2D%20Mes%20données%20personnelles%20périmètre%20ENGIE%20SA%2FMentions%20d%27informations%2FGroup%20Digital%20%26%20IT%20%28DSI%29%2FENGIE%20Directory" rel="noopener noreferrer" target="_blanc">here</a>.</p>
                    </div>
                }
                {this.state.language === "pt" &&
                    <div>
                        <h3>Information statement</h3><br />
                        <p>Please note that by activating this feature, the personal data on your business card may be shared with additional recipients. Your personal data are processed in the context of the ENGIE Directory in accordance with the applicable regulations.
                            For more information and to exercise your rights, you can consult the information notice on this processing <a href="https://engie.sharepoint.com/sites/MyEthicsCompliancePrivacy/Gouvernance%20des%20donnes/Forms/AllItems.aspx?id=%2Fsites%2FMyEthicsCompliancePrivacy%2FGouvernance%20des%20donnes%2FIntranet%20%2D%20Mes%20données%20personnelles%20périmètre%20ENGIE%20SA%2FMentions%20d%27informations%2FGroup%20Digital%20%26%20IT%20%28DSI%29%2FENGIE%20Directory%2FIN%20ENGIE%20Directory%2Epdf&viewid=af1068ee%2Dbf9e%2D4baf%2Da479%2D4c3d86190230&parent=%2Fsites%2FMyEthicsCompliancePrivacy%2FGouvernance%20des%20donnes%2FIntranet%20%2D%20Mes%20données%20personnelles%20périmètre%20ENGIE%20SA%2FMentions%20d%27informations%2FGroup%20Digital%20%26%20IT%20%28DSI%29%2FENGIE%20Directory" rel="noopener noreferrer" target="_blanc">here</a>.</p>
                    </div>
                }
                {this.state.language === "it" &&
                    <div>
                        <h3>Information statement</h3><br />
                        <p>Please note that by activating this feature, the personal data on your business card may be shared with additional recipients. Your personal data are processed in the context of the ENGIE Directory in accordance with the applicable regulations.
                            For more information and to exercise your rights, you can consult the information notice on this processing <a href="https://engie.sharepoint.com/sites/MyEthicsCompliancePrivacy/Gouvernance%20des%20donnes/Forms/AllItems.aspx?id=%2Fsites%2FMyEthicsCompliancePrivacy%2FGouvernance%20des%20donnes%2FIntranet%20%2D%20Mes%20données%20personnelles%20périmètre%20ENGIE%20SA%2FMentions%20d%27informations%2FGroup%20Digital%20%26%20IT%20%28DSI%29%2FENGIE%20Directory%2FIN%20ENGIE%20Directory%2Epdf&viewid=af1068ee%2Dbf9e%2D4baf%2Da479%2D4c3d86190230&parent=%2Fsites%2FMyEthicsCompliancePrivacy%2FGouvernance%20des%20donnes%2FIntranet%20%2D%20Mes%20données%20personnelles%20périmètre%20ENGIE%20SA%2FMentions%20d%27informations%2FGroup%20Digital%20%26%20IT%20%28DSI%29%2FENGIE%20Directory" rel="noopener noreferrer" target="_blanc">here</a>.</p>
                    </div>
                }
            </div>
        );
    }
});

