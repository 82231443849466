import { withOktaAuth } from '@okta/okta-react';
import React, { Component } from 'react';
import Modal from 'react-modal';
import LegalDisclaimer from './modals/legalDisclaimer.jsx';
import { FormattedMessage } from 'react-intl';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    maxWidth:               '1000px'
  }
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

export default withOktaAuth(class Footer extends Component {
  constructor() {
    super();
    this.state = {
      scrolled: false,
      modalIsOpen: false
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  async componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY > 50 && !this.state.scrolled) {
      this.setState({ scrolled: true })
    } else if (window.scrollY < 50 && this.state.scrolled) {
      this.setState({ scrolled: false })
    }
  }

  openModal() {
    this.setState({modalIsOpen: true});
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  render() {
    return (
      <div>
        <footer className="probootstrap-footer probootstrap-bg">
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Legal"
        >

          {/* <button onClick={this.closeModal}>close</button> */}
          <LegalDisclaimer />

        </Modal>
          <div className="container">
            <div className="row copyright">
              <div className="col-md-6">
                <div className="probootstrap-footer-widget">
                  <p><a href=" #" onClick={this.openModal}><FormattedMessage id="footer.legal"
                              defaultMessage="Mention d'information" /></a></p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="probootstrap-footer-widget right">
                  <ul className="probootstrap-footer-social">
                    <li><a href="https://twitter.com/ENGIEgroup" rel="noopener noreferrer" target="_blank"><i className="icon-twitter"></i></a></li>
                    <li><a href="https://www.facebook.com/EngieFR" rel="noopener noreferrer" target="_blank"><i className="icon-facebook"></i></a></li>
                    <li><a href="https://www.instagram.com/engie" rel="noopener noreferrer" target="_blank"><i className="icon-instagram2"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className={this.state.scrolled ? "gototop js-top active" : "gototop js-top"}>
          <a href="# " className="js-gotop"><i className="icon-chevron-thin-up"></i></a>
        </div>
      </div>
    );
  }
});
