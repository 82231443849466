function download(result) {
  var a = document.createElement('a');
  var filecontent= "BEGIN:VCARD\r\n";
  filecontent+="VERSION:3.0\r\n";
  filecontent+="FN:" + result.firstName + " " + result.lastName + "\r\n";
  filecontent+="N:" + result.lastName + ";" + result.firstName + "\r\n";
  filecontent+=`ORG:${result.organization ? result.organization : ""}\r\n`;
  filecontent+=`TEL;TYPE=WORK,VOICE:${result.primaryPhone ? result.primaryPhone : ""}\r\n`;
  filecontent+=`TEL;TYPE=WORK,FAX:${result.C_facsimileTelephoneNumber ? result.C_facsimileTelephoneNumber : ""}\r\n`;
  filecontent+=`TEL;TYPE=WORK,CELL:${result.mobilePhone ? result.mobilePhone : ""}\r\n`;
  filecontent+=`EMAIL:${result.email ? result.email : ""}\r\n`;
  filecontent+=`ADR;TYPE=WORK:;;${result.streetAddress ? result.streetAddress : ""}${result.zipCode ? " - " + result.zipCode : ""}${result.city ? " " + result.city : ""}\r\n`;
  filecontent+=`TITLE:${result.title ? result.title : ""}\r\n`;
  filecontent+="END:VCARD\r\n";

  var blob = new Blob([filecontent], { type: 'text/vcard' });
  a.href = URL.createObjectURL(blob);
  a.download = result.lastName + '_' + result.firstName;
  a.click()
  URL.revokeObjectURL(a.href)
  return;
}
export { download };