import { withOktaAuth } from '@okta/okta-react';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { countryArrray } from './Utils/country.js';
import { jobfamilies } from './Utils/jobfamilies.js';
import config from './config';

export default withOktaAuth(class Home extends Component {
  constructor() {
    super();
    this.search = "";
    this.state = {
      showIntuitiveResults: true,
      isAuthenticated: false,
      intuitiveResults: [],
      search: "/Result",
      userinfo: null,
      globalQuery: "",
      groupIdQuery: "",
      employeeTypeQuery: "",
      companyQuery: "",
      gbuQuery: "",
      firstNameQuery: "",
      lastNameQuery: "",
      primaryPhoneQuery: "",
      C_businessCategoryQuery: "",
      C_countryNameQuery: "",
      cityQuery: "",
      C_houseIdentifierQuery: "",
      titleQuery: "",
      showFilters: false,
      GBUs: [],
      enum: {
        Countries: countryArrray,
        JobFamilies: jobfamilies
      }
    };
    this.handleClick = this.handleClick.bind(this);
  }

  async componentDidMount() {
    localStorage.removeItem("globalQuery");
    localStorage.removeItem("groupIdQuery");
    localStorage.removeItem("employeeTypeQuery");
    localStorage.removeItem("companyQuery");
    localStorage.removeItem("gbuQuery");
    localStorage.removeItem("firstNameQuery");
    localStorage.removeItem("lastNameQuery");
    localStorage.removeItem("primaryPhoneQuery");
    localStorage.removeItem("C_businessCategoryQuery");
    localStorage.removeItem("C_countryNameQuery");
    localStorage.removeItem("cityQuery");
    localStorage.removeItem("C_houseIdentifierQuery");
    localStorage.removeItem("titleQuery");
    document.addEventListener('click', this.handleClick);
    this.setState({ isAuthenticated: this.props.authState?.isAuthenticated });
  }

  async componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps?.authState && this.props?.authState && prevProps.authState.isAuthenticated !== this.props.authState.isAuthenticated) {
      this.setState({ isAuthenticated: this.props.authState?.isAuthenticated });
    }
  }

  showFilters = async () => {
    const currentState = this.state.showFilters;
    let gbus = await this.getGBUs();
    this.setState({ showFilters: !currentState, GBUs: gbus });
  }

  handleInputChange = (e) => {
    let stateObj = this.state;
    stateObj[e.target.id] = e.target.value;
    this.setState({ [e.target.id]: e.target.value }, () => {
      if (e.target.id === "globalQuery" && e.target.value.length > 2) {
        clearTimeout(this.timeout);

        // Make a new timeout set to go off in 800ms
        this.timeout = setTimeout(() => {
          this.getIntuitiveInfo();
          if (!this.state.showIntuitiveResults) {
            this.setState({ showIntuitiveResults: true });
          }
        }, 500);
      }
      localStorage.setItem(e.target.id, e.target.value);
    });
  }

  handleClick() {
    if (this.state.showIntuitiveResults) {
      this.setState({ showIntuitiveResults: false, intuitiveResults: [] });
    }
  }

  async getGBUs() {
    try {
      var currentAccessToken = this.props.authState.accessToken.accessToken;
      const response = await fetch(config.env.resourceServer.getGBUsUrl + "?Status=Valid&order=Name ASC&select=Name,Code&index=0&itemPerPage=1000", {
        headers: {
          Authorization: `Bearer ${currentAccessToken}`
        },
      });

      if (response.status !== 200) {
        return;
      }

      const data = await response.json();
      return data;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  async getIntuitiveInfo() {
    try {
      let results = [];
      const accessToken = this.props.authState.accessToken.accessToken;
      let url = new URL(config.env.resourceServer.getOktaUsersUrl);

      let formatedGlobalQuery = this.state.globalQuery ? this.state.globalQuery.slice() : "";
      let updatedGroupIdQuery = "";
      let updatedPhoneNumberQuery = "";

      //Handle GroupID format
      if (this.state.globalQuery.match("^([a-zA-Z]{2}[0-9]{4})(-[a-zA-Z])?$|^([a-zA-Z]{3}[0-9]{3})(-[a-zA-Z])?$|^([a-zA-Z]{4}[0-9]{2})(-[a-zA-Z])?$")) {
        updatedGroupIdQuery = formatedGlobalQuery.slice();
        formatedGlobalQuery = "";
      } else if (this.state.globalQuery.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s0-9]*$/g)) {
        //Handle phone number format
        updatedPhoneNumberQuery = formatedGlobalQuery.substr(0, 1) === "0" ? formatedGlobalQuery.slice(1).replace(/[^0-9]/g, "") : formatedGlobalQuery.slice().replace(/[^0-9]/g, "");
        formatedGlobalQuery = "";
      }

      let params = {
        globalQuery: formatedGlobalQuery,
        groupIdQuery: updatedGroupIdQuery,
        primaryPhoneQuery: updatedPhoneNumberQuery,
        limit: 3,
        after: 0
      }
      url.search = new URLSearchParams(params);
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      if (response.status !== 200) {
        return;
      }

      const data = await response.json();
      data.forEach((element) => {
        results.push(element);
      });

      this.setState({ intuitiveResults: results }, () => {
        data.forEach((element) => {
          this.getIntuitivePhoto(element.login);
        });
      });
    } catch (err) {
      /* eslint-disable no-console */
      console.error(err);
    }
  }

  async getIntuitivePhoto(userId) {
    try {
      const accessToken = this.props.authState.accessToken.accessToken;
      const response = await fetch(config.env.resourceServer.getOktaUserPhotoUrl + "?userId=" + userId + "&size=240x240", {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
      });

      // if (response.status !== 200) {
      //   return;
      // }

      const data = await response.json();

      let domUser = document.getElementById(`intuitive-${userId}`);
      if (domUser) {
        domUser.src = data.image ? data.image : "img/default_profile_photo.png";
      }

    } catch (err) {
      console.error(err);
    }
  }

  render() {
    return (
      <div>
        {(!this.state) && <div className="probootstrap-loader"></div>}
        <div>
          {this.state.isAuthenticated &&
            <section className={this.state.showFilters ? "probootstrap-slider flexslider advancedsearch slider-home-heigth" : "probootstrap-slider flexslider slider-home-heigth"}>
              <div className="home-probootstrap-wrap-banner">
                <div className="container">
                  <div className="row">
                    <div className="col-md-10 offset-md-1">
                      <div className="probootstrap-home-search engiegradient-background">
                        <form>
                          <h2 className="heading"><FormattedMessage id="home.search_title"
                            defaultMessage="Search people here" /></h2>
                          <div className="probootstrap-field-group">
                            <div className="form-row">
                              <div className="form-group col-md-12">
                                <div className="search-field">
                                  <input type="text" className="form-control" aria-label="search-button" id="globalQuery" value={this.state.globalQuery} onChange={this.handleInputChange} autoComplete="off" />
                                  {this.state.intuitiveResults.length > 0 &&
                                    <div className={this.state.showIntuitiveResults ? "dropdown-menu intuitive-menu show" : "dropdown-menu"}>
                                      {this.state.intuitiveResults.map((element) => {
                                        var a = this.state.intuitiveResults.indexOf(element);
                                        return <a ref={this.setWrapperRef} key={a} href={'/Profile?' + element.login} className="dropdown-item intuitive-result">
                                          <div className="profile-img intuitive-img">
                                            <img id={"intuitive-" + element.login} src="img/default_profile_photo.png" className="img-responsive" alt="User profile" />
                                          </div>
                                          <div className="intuitive-description">
                                            {element.firstName || element.lastName ? element.firstName + " " + element.lastName.toUpperCase() : "UNKNWOWN USER"}</div>
                                        </a>
                                      })}
                                      <div ref={this.setWrapperRef} className="intuitive-result">
                                        <Link to={{ pathname: this.state.search, query: this.state }} className="dropdown-item">
                                          <FormattedMessage id="home.more" defaultMessage="More" />
                                        </Link></div>
                                    </div>
                                  }
                                </div>
                              </div>
                            </div>
                            {this.state.showFilters &&
                              <div>
                                <div className="form-row">
                                  <div className="form-group col-md-3">
                                    <label id="lastnameLabel"><FormattedMessage id="field.lastname" defaultMessage="Last name" /></label>
                                    <input type="text" className="form-control" value={this.state.lastNameQuery} onChange={this.handleInputChange} id="lastNameQuery" />
                                  </div>
                                  <div className="form-group col-md-3">
                                    <label id="firstnameLabel"><FormattedMessage id="field.firstname" defaultMessage="First name" /></label>
                                    <input type="text" className="form-control" value={this.state.firstNameQuery} onChange={this.handleInputChange} id="firstNameQuery" />
                                  </div>
                                  <div className="form-group col-md-3">
                                    <label id="groupIdLabel"><FormattedMessage id="field.groupid" defaultMessage="Group ID" /></label>
                                    <input type="text" className="form-control" value={this.state.groupIdQuery} onChange={this.handleInputChange} id="groupIdQuery" />
                                  </div>
                                  <div className="form-group col-md-3">
                                    <label id="employeeTypeLabel"><FormattedMessage id="field.employeetype" defaultMessage="Employee Type" /></label>
                                    <select id="employeeTypeQuery" className="form-control" value={this.state.employeeTypeQuery} onChange={this.handleInputChange}>
                                      <option value="">Internal/External</option>
                                      <option value="I">Internal</option>
                                      <option value="E">External</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="form-row">
                                  <div className="form-group col-md-3">
                                    <label id="gbuLabel">GBU</label>
                                    <select className="form-control" value={this.state.gbuQuery} onChange={this.handleInputChange} id="gbuQuery">
                                      <option value=""></option>
                                      {this.state.GBUs.map((element) => {
                                        return <option key={element.Code} value={element.Code}>{element.Name}</option>;
                                      })}
                                    </select>
                                  </div>
                                  <div className="form-group col-md-3">
                                    <label id="companyLabel"><FormattedMessage id="field.company" defaultMessage="Company" /></label>
                                    <input type="text" className="form-control" value={this.state.companyQuery} onChange={this.handleInputChange} id="companyQuery" />
                                  </div>
                                  <div className="form-group col-md-3">
                                    <label id="jobfamilyLabel"><FormattedMessage id="field.jobfamily" defaultMessage="Job family" /></label>
                                    <select className="form-control" value={this.state.C_businessCategoryQuery} onChange={this.handleInputChange} id="C_businessCategoryQuery">
                                      <option value=""></option>
                                      {this.state.enum.JobFamilies.map((element) => {
                                        return <FormattedMessage id={"field.jobfamily." + element} key={element}>
                                          {(message) => <option key={element} value={element}>{message ? message : element}</option>}
                                        </FormattedMessage>;
                                      })}
                                    </select>
                                  </div>
                                  <div className="form-group col-md-3">
                                    <label id="functionLabel"><FormattedMessage id="field.function" defaultMessage="Function" /></label>
                                    <input type="text" className="form-control" value={this.state.titleQuery} onChange={this.handleInputChange} id="titleQuery" />
                                  </div>
                                </div>
                                <div className="form-row">
                                  <div className="form-group col-md-3">
                                    <label id="phonenumberLabel"><FormattedMessage id="field.phonenumber" defaultMessage="Phone number" /></label>
                                    <input type="text" className="form-control" value={this.state.primaryPhoneQuery} onChange={this.handleInputChange} id="primaryPhoneQuery" />
                                  </div>
                                  <div className="form-group col-md-3">
                                    <label id="countryLabel"><FormattedMessage id="field.country" defaultMessage="Country" /></label>
                                    <select className="form-control" onChange={this.handleInputChange} id="C_countryNameQuery">
                                      <option value=""></option>
                                      {this.state.enum.Countries.map((element) => {
                                        return <option key={element.id} value={element.name}>{element.name}</option>;
                                      })}
                                    </select>
                                  </div>
                                  <div className="form-group col-md-3">
                                    <label id="cityLabel"><FormattedMessage id="field.city" defaultMessage="City" /></label>
                                    <input type="text" className="form-control" value={this.state.cityQuery} onChange={this.handleInputChange} id="cityQuery" />
                                  </div>
                                  <div className="form-group col-md-3">
                                    <label id="siteLabel"><FormattedMessage id="field.site" defaultMessage="Site" /></label>
                                    <input type="text" className="form-control" value={this.state.C_houseIdentifierQuery} onChange={this.handleInputChange} id="C_houseIdentifierQuery" />
                                  </div>
                                </div>
                              </div>
                            }
                            <div className="form-group col-md-12">
                              <div className="right">
                                <div className="form-group col-md-6">
                                  <small className="advancedlink"><a href="# " role="button" onClick={this.showFilters}><i className={this.state.showFilters ? "icon-squared-minus" : "icon-squared-plus"}></i> <FormattedMessage id="home.advanced_search"
                                    defaultMessage="Advanced search" /></a></small>
                                </div>
                                <div className="form-group col-md-6">
                                  <Link to={{ pathname: this.state.search, query: this.state }}>
                                    <button className="btn btn-success btn-success-engie" type="submit">
                                      <i className="icon-magnifying-glass t2"></i> <FormattedMessage id="home.search"
                                        defaultMessage="Start Search" /></button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          }
          {!this.state.isAuthenticated &&
            <div>
              <p>
                You need to be logged to access to Himawari.
              </p>
            </div>
          }
        </div>
      </div>
    );
  }
});
