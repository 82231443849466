import { withOktaAuth } from '@okta/okta-react';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import config from './config';

export default withOktaAuth(class Navbar extends Component {
  constructor() {
    super();
    this.state = { active: false, width: window.innerWidth, search: "", showProfileMenu: false, ready: false, currentUser: null, route: window.location.pathname, isAuthenticated: false, start: false };
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.setPhotoRef = this.setPhotoRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.toggleClass = this.toggleClass.bind(this);
    this.showProfileMenu = this.showProfileMenu.bind(this);
  }

  async componentDidMount() {
    try {
      let _user = await this.props.oktaAuth.getUser();
      let isadmin = await this.checkAdmin(_user);
      this.getLoggedUserPhoto(_user);
      this.setState({ userinfo: _user, isAuthenticated: this.props.authState?.isAuthenticated, currentUser: this.state.userinfo, isAdmin: isadmin });
    } catch (err) {
      console.log(err);
    }
    window.addEventListener("resize", this.updateDimensions);
    document.addEventListener('click', this.handleClickOutside);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps?.authState && this.props?.authState && prevProps.authState.isAuthenticated !== this.props.authState.isAuthenticated) {
      try {
        let _user = await this.props.oktaAuth.getUser();
        let isadmin = await this.checkAdmin(_user);
        this.getLoggedUserPhoto(_user);
        this.setState({ userinfo: _user, isAuthenticated: this.props.authState?.isAuthenticated, currentUser: this.state.userinfo, isAdmin: isadmin });
      } catch (err) {
        console.log(err);
      }
    }
  }

  async checkAdmin(_user) {
    try {
      let accessToken = this.props.authState.accessToken.accessToken;
      let response = await fetch(`${config.env.resourceServer.admin.users}/${_user.preferred_username}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
      });
      let data = await response.json();

      if (data === true) {
        return true;
      }
    } catch (err) {
      console.error(err);
    }
    return false;
  }

  login() {
    this.props.oktaAuth.signInWithRedirect();
  }

  async logout() {
    this.props.oktaAuth.signOut('/');
  }

  async getLoggedUserPhoto(_user) {
    try {
      var size = "240x240";
      const accessToken = this.props.authState.accessToken.accessToken;
      const response = await fetch(config.env.resourceServer.getOktaUserPhotoUrl + "?userId=" + _user.preferred_username + "&size=" + size, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
      });

      if (response.status !== 200) {
        return;
      }

      const data = await response.json();
      if (!data.error) {
        let domUser = document.getElementById("LoggedUser");
        if (domUser) {
          domUser.src = data.image;
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  toggleClass() {
    const currentState = this.state.active;
    this.setState({ active: !currentState });
    if (this.state.showProfileMenu) {
      this.showProfileMenu();
    }
  };

  setWrapperRef(node) {
    this.wrapperRef = node;
  }
  setPhotoRef(node) {
    this.photoRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.state.active) {
      this.toggleClass();
    }
    if (this.photoRef && !this.photoRef.contains(event.target) && this.state.showProfileMenu) {
      this.showProfileMenu();
    }
  }

  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }

  showProfileMenu() {
    let temp = this.state.showProfileMenu;
    this.setState({ showProfileMenu: !temp });
  }

  render() {
    return (
      <div>
        {this.start && <div className={this.state.start ? 'aze' : 'rty'}></div>}

        {(this.state && !this.state.isAuthenticated) && <div className="probootstrap-loader"></div>}
        {(this.state && this.state.isAuthenticated) &&
          <div role="banner" className={this.state.active && this.state.width < 766 ? 'probootstrap-header probootstrap-mobile-menu-active show' : 'probootstrap-header'}>
            <div className="container">
              <a href="/"><img className="logoengie" src="img/logo-engie-blue.svg" alt="ENGIE" />
              </a>

              <a ref={this.setWrapperRef} href="# " className={this.state.active ? 'probootstrap-burger-menu active visible-xs' : 'probootstrap-burger-menu visible-xs'} onClick={this.toggleClass}><i>Menu</i></a>
              <div className="mobile-menu-overlay"></div>

              <nav ref={this.setPhotoRef} className="probootstrap-nav hidden-xs">
                {this.state.isAuthenticated &&
                  <ul className="probootstrap-main-nav margin-rigth-nav heigth-0">
                    <li className={this.state.route === '/' ? "active" : ""}><Link to="/" className="probootstrap-customresultlink" onClick={() => this.setState({ route: "/" })}><FormattedMessage id="navbar.home" defaultMessage="Home" /></Link></li>
                    <li className={this.state.route === '/Result' ? "active" : ""}><Link to="/Result" className="probootstrap-customresultlink" onClick={() => this.setState({ route: "/Result" })}><FormattedMessage id="navbar.directory" defaultMessage="Directory" /></Link></li>
                    {this.state.isAdmin === true &&
                      <li className={this.state.route === '/Settings' ? "active" : ""}><Link to="/Settings" className="probootstrap-customresultlink" onClick={() => this.setState({ route: "/Settings" })}>SETTINGS</Link></li>
                    }
                    <li className="nav-item dropdown profile-img" onClick={this.showProfileMenu}>
                      <img src="img/default_profile_photo.png" id="LoggedUser" className="profile-img-navbar" alt="" role="button" />
                      <div className={this.state.showProfileMenu ? "dropdown-menu show" : "dropdown-menu"} aria-labelledby="navbarDropdown">
                        <Link to="/myprofile" className="dropdown-item" onClick={() => this.setState({ route: "/myprofile" })}> <FormattedMessage id="navbar.profile" defaultMessage="My profile" /></Link>
                        <a href="# " onClick={this.logout} className="dropdown-item probootstrap-customresultlink hidden-xs"> <FormattedMessage id="navbar.logout" defaultMessage="Logout" /></a>
                      </div>
                    </li>


                    <li className={window.location.pathname === '/myprofile' ? "active visible-xs" : "visible-xs"}><Link to="/myprofile" className="probootstrap-customresultlink"><FormattedMessage id="navbar.profile" defaultMessage="My profile" /></Link></li>
                    <li><a href="# " onClick={this.logout} className="probootstrap-customresultlink visible-xs"><FormattedMessage id="navbar.logout" defaultMessage="Logout" /></a></li>
                  </ul>
                }
                {!this.state.isAuthenticated &&
                  <ul className="probootstrap-main-nav">
                    <li><a href="# " onClick={this.login}>Login</a></li>
                  </ul>
                }
              </nav>
            </div>
          </div>
        }

      </div>
    );
  }
});
