import React, { Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import config from './config';
import { BUs } from './Utils/BU.js';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log('totally custom!'),
  );

  return (
    <Button variant='link' onClick={decoratedOnClick}>
      {children}
    </Button>
  );
}
export default withOktaAuth(class Settings extends Component {
  constructor() {
    super();
    this.state = {
      userinfo: null,
      admins: [],
      editableFields: [
        "primaryPhone",
        "mobilePhone",
        "C_ipPhone",
        "C_facsimileTelephoneNumber",
        "C_assistant",
        "C_physicalDeliveryOfficeName",
        "C_houseIdentifier",
        "preferredLanguage",
        "streetAddress",
        "city",
        "C_countryname",
        "state",
        "zipCode"
      ],
      organizations: [],
      domains: [],
      addresses: [],
      isAdmin: false,
      selectedMenu: '',
      selectedAction: '',
      form: {},
      ready: false,
      bus: BUs
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.form = React.createRef();
  }

  async componentDidMount() {
    let _user = await this.props.oktaAuth.getUser();
    this.setState({ userinfo: _user }, async () => {
      try {
        let accessToken = this.props.authState.accessToken.accessToken;
        let res = await fetch(config.env.resourceServer.admin.users, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
        });
        let admins = await res.json();

        this.setState({
          admins: admins,
          ready: true,
          isAdmin: admins.indexOf(this.state.userinfo.preferred_username.toUpperCase()) !== -1
        })
      } catch (err) {
        console.error(err);
        this.setState({ ready: true });
      }
    });
  }

  async componentWillUnmount() {
  }

  async actionSelected(target, action) {
    this.setState({
      ready: false,
      selectedMenu: target,
      selectedAction: action,
      form: {}
    }, async () => {
      try {
        let accessToken = this.props.authState.accessToken.accessToken;
        if (target === 'domains' || target === 'organizations') {
          this.setState({
            form: {
              primaryPhone: true,
              mobilePhone: true,
              C_ipPhone: true,
              C_facsimileTelephoneNumber: true,
              C_assistant: true,
              C_physicalDeliveryOfficeName: true,
              C_houseIdentifier: true,
              preferredLanguage: true,
              streetAddress: true,
              city: true,
              C_countryname: true,
              state: true,
              zipCode: true
            }
          })
        }
        if (target === 'addresses' && action === 'change') {
          let res = await fetch(config.env.resourceServer.admin.buAddresses, {
            headers: {
              Authorization: `Bearer ${accessToken}`
            },
          });
          let addresses = await res.json();
          this.setState({
            addresses: addresses,
          });
        }
        if (target === 'domains' && action === 'change') {
          this.getDomains();
        }
        if (target === 'organizations' && action === 'change') {
          this.getOrganizations();
        }
        if (target === 'admins' && action === 'change') {
          let res = await fetch(config.env.resourceServer.admin.users, {
            headers: {
              Authorization: `Bearer ${accessToken}`
            },
          });
          let admins = await res.json();
          this.setState({
            admins: admins,
            ready: true
          });
        }

        window.scrollTo(0, this.form.current.offsetTop);
      } catch (err) {
        console.error(err);
      }
      this.setState({ ready: true });
    });
  }

  async getDomains() {
    try {
      let accessToken = this.props.authState.accessToken.accessToken;
      let res = await fetch(config.env.resourceServer.admin.selfServiceDomains, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
      });
      let domains = await res.json();
      this.setState({
        domains: domains,
      });
    } catch (error) {
      console.error(error);
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let form = this.state.form;
    form[name] = value;
    this.setState({
      form: form
    });
  }

  async removeAdmin(upn) {
    this.setState({ ready: false });
    try {
      let accessToken = this.props.authState.accessToken.accessToken;
      await fetch(`${config.env.resourceServer.admin.users}/${upn}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      let index = this.state.admins.indexOf(upn);
      let admins = this.state.admins;
      admins.splice(index, 1);
      this.setState({ admins: admins, ready: true });
    } catch (err) {
      this.setState({ ready: true });
      console.error(err);
    }
  }

  async addAdmin() {
    this.setState({ ready: false });
    try {
      let accessToken = this.props.authState.accessToken.accessToken;
      await fetch(config.env.resourceServer.admin.users, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userLogin: this.state.form.groupId + "@" + config.env.domain
        })
      });
    } catch (err) {
      console.error(err);
    }
    this.setState({
      ready: true,
      form: {}
    });
  }

  async addAddresses() {
    this.setState({ ready: false });
    try {
      let accessToken = this.props.authState.accessToken.accessToken;
      await fetch(config.env.resourceServer.admin.buAddresses, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: this.state.json
      });

    } catch (err) {
      console.error(err);
    }
    this.setState({
      ready: true,
      form: {}
    });
  }

  async removeAllBUAddresses(bu) {
    this.setState({ ready: false });
    try {
      let accessToken = this.props.authState.accessToken.accessToken;
      await fetch(`${config.env.resourceServer.admin.buAddresses}/${bu}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      let index = this.state.addresses.findIndex(a => a.bu === bu);
      let addresses = this.state.addresses;
      addresses.splice(index, 1);
      this.setState({ addresses: addresses, ready: true });
    } catch (err) {
      this.setState({ ready: true });
      console.error(err);
    }
  }

  async removeDomain(domain) {
    this.setState({ ready: false });
    try {
      let accessToken = this.props.authState.accessToken.accessToken;
      await fetch(`${config.env.resourceServer.admin.selfServiceDomains}/${domain}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      let index = this.state.domains.findIndex(d => d.id === domain);
      let domains = this.state.domains;
      domains.splice(index, 1);
      this.setState({ domains: domains, ready: true });
    } catch (err) {
      this.setState({ ready: true });
      console.error(err);
    }
  }

  async addDomain() {
    this.setState({ ready: false });
    try {
      let accessToken = this.props.authState.accessToken.accessToken;
      await fetch(config.env.resourceServer.admin.selfServiceDomains, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.state.form)
      });
    } catch (err) {
      console.error(err);
    }
    this.setState({
      ready: true,
      form: {}
    });
  }

  async updateDomain() {
    this.setState({ ready: false });
    try {
      let accessToken = this.props.authState.accessToken.accessToken;
      await fetch(config.env.resourceServer.admin.selfServiceDomains, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.state.form)
      });
      await this.getDomains();
    } catch (err) {
      console.error(err);
    }
    this.setState({
      ready: true,
      form: {}
    });
  }

  async editDomainSelected(domain) {
    if (this.state.form.domain !== domain) {
      let element = this.state.domains.find(d => d.id === domain);
      this.setState({
        form: {
          domain: domain,
          primaryPhone: element.allowedAttributes.indexOf("primaryPhone") !== -1,
          mobilePhone: element.allowedAttributes.indexOf("mobilePhone") !== -1,
          C_ipPhone: element.allowedAttributes.indexOf("C_ipPhone") !== -1,
          C_facsimileTelephoneNumber: element.allowedAttributes.indexOf("C_facsimileTelephoneNumber") !== -1,
          C_assistant: element.allowedAttributes.indexOf("C_assistant") !== -1,
          C_physicalDeliveryOfficeName: element.allowedAttributes.indexOf("C_physicalDeliveryOfficeName") !== -1,
          C_houseIdentifier: element.allowedAttributes.indexOf("C_houseIdentifier") !== -1,
          preferredLanguage: element.allowedAttributes.indexOf("preferredLanguage") !== -1,
          streetAddress: element.allowedAttributes.indexOf("streetAddress") !== -1,
          city: element.allowedAttributes.indexOf("city") !== -1,
          C_countryname: element.allowedAttributes.indexOf("C_countryname") !== -1,
          state: element.allowedAttributes.indexOf("state") !== -1,
          zipCode: element.allowedAttributes.indexOf("zipCode") !== -1
        }
      });
    } else {
      this.setState({
        form: {}
      });
    }
  }

  async getOrganizations() {
    try {
      let accessToken = this.props.authState.accessToken.accessToken;
      let res = await fetch(config.env.resourceServer.admin.selfServiceOrganizations, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
      });
      let organizations = await res.json();
      this.setState({
        organizations: organizations,
      });
    } catch (error) {
      console.error(error);
    }
  }

  async removeOrganization(organization) {
    this.setState({ ready: false });
    try {
      let accessToken = this.props.authState.accessToken.accessToken;
      await fetch(`${config.env.resourceServer.admin.selfServiceOrganizations}/${organization}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      let index = this.state.organizations.findIndex(o => o.id === organization);
      let organizations = this.state.organizations;
      organizations.splice(index, 1);
      this.setState({ organizations: organizations, ready: true });
    } catch (err) {
      this.setState({ ready: true });
      console.error(err);
    }
  }

  async addOrganization() {
    this.setState({ ready: false });
    try {
      let accessToken = this.props.authState.accessToken.accessToken;
      await fetch(config.env.resourceServer.admin.selfServiceOrganizations, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.state.form)
      });
    } catch (err) {
      console.error(err);
    }
    this.setState({
      form: {},
      ready: true
    });
  }

  async updateOrganization() {
    this.setState({ ready: false });
    try {
      let accessToken = this.props.authState.accessToken.accessToken;
      await fetch(config.env.resourceServer.admin.selfServiceOrganizations, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.state.form)
      });
      await this.getOrganizations();
    } catch (err) {
      console.error(err);
    }
    this.setState({
      ready: true,
      form: {}
    });
  }

  async editOrganizationSelected(organization) {
    if (this.state.form.organization !== organization) {
      let element = this.state.organizations.find(o => o.id === organization);
      this.setState({
        form: {
          organization: organization,
          primaryPhone: element.allowedAttributes.indexOf("primaryPhone") !== -1,
          mobilePhone: element.allowedAttributes.indexOf("mobilePhone") !== -1,
          C_ipPhone: element.allowedAttributes.indexOf("C_ipPhone") !== -1,
          C_facsimileTelephoneNumber: element.allowedAttributes.indexOf("C_facsimileTelephoneNumber") !== -1,
          C_assistant: element.allowedAttributes.indexOf("C_assistant") !== -1,
          C_physicalDeliveryOfficeName: element.allowedAttributes.indexOf("C_physicalDeliveryOfficeName") !== -1,
          C_houseIdentifier: element.allowedAttributes.indexOf("C_houseIdentifier") !== -1,
          preferredLanguage: element.allowedAttributes.indexOf("preferredLanguage") !== -1,
          streetAddress: element.allowedAttributes.indexOf("streetAddress") !== -1,
          city: element.allowedAttributes.indexOf("city") !== -1,
          C_countryname: element.allowedAttributes.indexOf("C_countryname") !== -1,
          state: element.allowedAttributes.indexOf("state") !== -1,
          zipCode: element.allowedAttributes.indexOf("zipCode") !== -1
        }
      });
    } else {
      this.setState({
        form: {}
      });
    }

  }

  async editBUAddressesSelected(bu) {
    if (this.state.form.bu !== bu) {
      this.setState({
        form: {
          bu: bu
        }
      });
    } else {
      this.setState({
        form: {}
      });
    }
  }

  async updateAddresses() {
    this.setState({ ready: false });
    try {
      let json = JSON.parse(this.state.json);
      if (json.bu !== this.state.form.bu) {
        throw new Error(`BU name ${this.state.form.bu} doesn't correspond to json ${json.bu}`);
      }
      let accessToken = this.props.authState.accessToken.accessToken;
      await fetch(config.env.resourceServer.admin.buAddresses, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: this.state.json
      });
    } catch (err) {
      console.error(err);
    }
    this.setState({
      form: {},
      ready: true
    });
  }

  async handleFile(e) {
    let form = this.state.form;
    let res = JSON.parse(e.target.result);
    form["addressesCount"] = res.allowedAddresses.length;
    this.setState({
      json: e.target.result,
      form: form
    });
  }

  async onChangeFile(event) {
    if (event.target.files.length > 0) {
      event.stopPropagation();
      event.preventDefault();
      if (event.target.files[0].type !== 'application/json') {
        alert("The selected file is not a json file.");
        return
      }
      var reader = new FileReader();
      reader.onloadend = this.handleFile;
      reader.readAsText(event.target.files[0]);
      let form = this.state.form;
      form["fileName"] = event.target.files[0].name;
    }
  }

  handleSubmit() {
    console.log(this.state.form);
  }

  download(bu) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify(this.state.addresses.find(x => x.bu === bu))));
    element.setAttribute('download', `${bu}.json`);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  render() {
    return (
      <div>
        {!this.state.ready && <div className="probootstrap-loader"></div>}
        <section className="probootstrap-slider flexslider2 page-inner">
          <div className="overlay engiegradient-background"></div>
          <div className="probootstrap-wrap-banner">
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <div className="page-title">
                    <div className="probootstrap-breadcrumbs">
                      <a href="/">Home</a><span>Settings</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div id="main-wrapper">
          {this.state.isAdmin === true &&
            <div className="settings-block container">
              <div className="settings-title">
                Site administration
              </div>
              <div className="settings-subtitle">
                <span>AUTHORIZATION</span>
              </div>
              <div className="settings-action engie-blue">
                <span>Admins</span>
                <div title="Add an admin" className="settings-add" onClick={() => { this.actionSelected('admins', 'add') }}><i className="ms-Icon ms-Icon--Add" aria-hidden="true"></i></div><div className="settings-change" onClick={() => { this.actionSelected('admins', 'change') }}><i className="ms-Icon ms-Icon--Edit" aria-hidden="true"></i></div>
              </div>
              <div className="settings-subtitle">
                <span>SELF SERVICE</span>
              </div>
              <div className="settings-action engie-blue">
                <span>Organizations</span>
                <div title="Add an organization" className="settings-add" onClick={() => { this.actionSelected('organizations', 'add') }}><i className="ms-Icon ms-Icon--Add" aria-hidden="true"></i></div><div className="settings-change" onClick={() => { this.actionSelected('organizations', 'change') }}><i className="ms-Icon ms-Icon--Edit" aria-hidden="true"></i></div>
              </div>
              <div className="settings-action engie-blue">
                <span>Domains</span>
                <div title="Add a domain" className="settings-add" onClick={() => { this.actionSelected('domains', 'add') }}><i className="ms-Icon ms-Icon--Add" aria-hidden="true"></i></div><div className="settings-change" onClick={() => { this.actionSelected('domains', 'change') }}><i className="ms-Icon ms-Icon--Edit" aria-hidden="true"></i></div>
              </div>
              <div className="settings-action engie-blue">
                <span>Addresses</span>
                <div title="Add bu addresses" className="settings-add" onClick={() => { this.actionSelected('addresses', 'add') }}><i className="ms-Icon ms-Icon--Add" aria-hidden="true"></i></div><div className="settings-change" onClick={() => { this.actionSelected('addresses', 'change') }}><i className="ms-Icon ms-Icon--Edit" aria-hidden="true"></i></div>
              </div>
            </div>
          }
          <div ref={this.form}>
            {this.state.selectedMenu === 'admins' && this.state.selectedAction === 'add' &&
              <div id="form" className="settings-block container">
                <div className="settings-title">
                  Admins
                </div>
                <div className="settings-subtitle">
                  <span>ADD AN ADMIN</span>
                </div>
                <div className="settings-form engie-blue">
                  <form>
                    <label>
                      <span>GroupID</span>
                      <input className="form-control" type="text" name="groupId" value={this.state.form.groupId ? this.state.form.groupId : ""} onChange={this.handleInputChange} />
                    </label>
                    <span className="btn btn-success btn-success-engie settings-save" onClick={() => this.addAdmin()}>Submit</span>
                  </form>
                </div>
              </div>
            }
            {this.state.selectedMenu === 'addresses' && this.state.selectedAction === 'add' &&
              <div id="form" className="settings-block container">
                <div className="settings-title">
                  Addresses
                </div>
                <div className="settings-subtitle">
                  <span>ADD BU ADDRESSES</span>
                </div>
                <div className="settings-form engie-blue">
                  <form className="add-address-settings-form">
                    <div className="settings-add-addresses" onClick={() => { this.upload.click() }}><i className="ms-Icon ms-Icon--Upload" aria-hidden="true"></i></div>
                    <input ref={(ref) => this.upload = ref} type="file" accept="*.json" style={{ display: "none" }} onChange={this.onChangeFile.bind(this)} />
                    {this.state.form.fileName &&
                      <div>
                        <span>Import {this.state.form.fileName} with {this.state.form.addressesCount} allowed addresses.</span>
                      </div>
                    }
                    {this.state.form.addressesCount &&
                      <span className="btn btn-success btn-success-engie settings-save" onClick={() => this.addAddresses()}>Submit</span>
                    }
                    {!this.state.form.addressesCount &&
                      <span className="btn btn-success btn-success-engie settings-save disabled">Submit</span>
                    }
                  </form>
                </div>
              </div>
            }
            {this.state.selectedMenu === 'domains' && this.state.selectedAction === 'add' &&
              <div id="form" className="settings-block container">
                <div className="settings-title">
                  Domains
                </div>
                <div className="settings-subtitle">
                  <span>ADD A DOMAIN</span>
                </div>
                <div className="settings-form engie-blue">
                  <form>
                    <div className="form-group">
                      <label>
                        <span>Name</span>
                        <input className="form-control" type="text" name="domain" value={this.state.form.domain ? this.state.form.domain : ""} onChange={this.handleInputChange} />
                      </label>
                    </div>
                    {this.state.editableFields.map((element) => {
                      return <div key={element} className="form-group form-check settings-checkbox">
                        <input type="checkbox" name={element} className="editable-field-checkbox" checked={this.state.form[element] === true} onChange={this.handleInputChange} />
                        <label className="form-check-label">{element}</label>
                      </div>;
                    })}
                    <span className="btn btn-success btn-success-engie settings-save" type="submit" onClick={() => this.addDomain()}>Submit</span>
                  </form>
                </div>
              </div>
            }
            {this.state.selectedMenu === 'domains' && this.state.selectedAction === 'change' &&
              <div id="form" className="settings-block container">
                <div className="settings-title">
                  Domains
                </div>
                <div className="settings-subtitle">
                  <span>CHANGE</span>
                </div>
                <Accordion>
                  {this.state.domains.map((element) => {
                    return <Card key={element.id} className="settings-form engie-blue change">
                      <Card.Header className="domain-settings">
                        <CustomToggle eventKey={element.id}>{element.id}</CustomToggle>
                        <div className="settings-remove" onClick={() => { this.removeDomain(element.id) }}><i className="ms-Icon ms-Icon--Delete" aria-hidden="true"></i></div>
                      </Card.Header>
                      <Accordion.Collapse eventKey={element.id}>
                        <Card.Body className="domain-settings-card-body">
                          <div className="settings-change-domain" onClick={() => { this.editDomainSelected(element.id) }}><i className="ms-Icon ms-Icon--Edit" aria-hidden="true"></i></div>
                          <form>
                            {this.state.editableFields.map((field) => {
                              return <div key={field} className="form-group form-check domain-update">
                                {this.state.form.domain !== element.id &&
                                  <input type="checkbox" name={field} className="editable-field-checkbox" checked={element.allowedAttributes.indexOf(field) !== -1} onChange={this.handleInputChange} disabled />
                                }
                                {this.state.form.domain === element.id &&
                                  <input type="checkbox" name={field} className="editable-field-checkbox" checked={this.state.form[field]} onChange={this.handleInputChange} />
                                }
                                <label className="form-check-label">{field}</label>
                              </div>;
                            })}
                            {this.state.form.domain === element.id &&
                              <div className="form-group">
                                <span className="btn btn-success btn-success-engie settings-save" type="submit" onClick={() => this.updateDomain()}>Submit</span>
                              </div>
                            }
                          </form>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>;
                  })}
                </Accordion>

              </div>
            }
            {this.state.selectedMenu === 'organizations' && this.state.selectedAction === 'add' &&
              <div id="form" className="settings-block container">
                <div className="settings-title">
                  Organizations
                </div>
                <div className="settings-subtitle">
                  <span>ADD AN ORGANIZATION</span>
                </div>
                <div className="settings-form engie-blue">
                  <form>
                    <div className="form-group">
                      <label>
                        <span>Name (OrgID)</span>
                        <input className="form-control" type="text" name="organization" value={this.state.form.organization ? this.state.form.organization : ""} onChange={this.handleInputChange} />
                      </label>
                    </div>
                    {this.state.editableFields.map((element) => {
                      return <div key={element} className="form-group form-check settings-checkbox">
                        <input type="checkbox" name={element} className="editable-field-checkbox" checked={this.state.form[element] === true} onChange={this.handleInputChange} />
                        <label className="form-check-label">{element}</label>
                      </div>;
                    })}
                    <span className="btn btn-success btn-success-engie settings-save" type="submit" onClick={() => this.addOrganization()}>Submit</span>
                  </form>
                </div>
              </div>
            }
            {this.state.selectedMenu === 'organizations' && this.state.selectedAction === 'change' &&
              <div id="form" className="settings-block container">
                <div className="settings-title">
                  Organizations
                </div>
                <div className="settings-subtitle">
                  <span>CHANGE</span>
                </div>
                <Accordion>
                  {this.state.organizations.map((element) => {
                    return <Card key={element.id} className="settings-form engie-blue change">
                      <Card.Header className="domain-settings">
                        <CustomToggle eventKey={element.id}>{element.id}</CustomToggle>
                        <div className="settings-remove" onClick={() => { this.removeOrganization(element.id) }}><i className="ms-Icon ms-Icon--Delete" aria-hidden="true"></i></div>
                      </Card.Header>
                      <Accordion.Collapse eventKey={element.id}>
                        <Card.Body className="domain-settings-card-body">
                          <div className="settings-change-domain" onClick={() => { this.editOrganizationSelected(element.id) }}><i className="ms-Icon ms-Icon--Edit" aria-hidden="true"></i></div>
                          <form>
                            {this.state.editableFields.map((field) => {
                              return <div key={field} className="form-group form-check domain-update">
                                {this.state.form.organization !== element.id &&
                                  <input type="checkbox" name={field} className="editable-field-checkbox" checked={element.allowedAttributes.indexOf(field) !== -1} onChange={this.handleInputChange} disabled />
                                }
                                {this.state.form.organization === element.id &&
                                  <input type="checkbox" name={field} className="editable-field-checkbox" checked={this.state.form[field]} onChange={this.handleInputChange} />
                                }
                                <label className="form-check-label">{field}</label>
                              </div>;
                            })}
                            {this.state.form.organization === element.id &&
                              <div className="form-group">
                                <span className="btn btn-success btn-success-engie settings-save" type="submit" onClick={() => this.updateOrganization()}>Submit</span>
                              </div>
                            }
                          </form>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>;
                  })}
                </Accordion>

              </div>
            }
            {this.state.selectedMenu === 'addresses' && this.state.selectedAction === 'change' &&
              <div id="form" className="settings-block container">
                <div className="settings-title">
                  Addresses
                </div>
                <div className="settings-subtitle">
                  <span>CHANGE</span>
                </div>
                <Accordion>
                  {this.state.addresses.map((element) => {
                    return <Card key={element.bu} className="settings-form engie-blue change">
                      <Card.Header className="domain-settings">
                        <CustomToggle eventKey={element.bu}>{element.bu}</CustomToggle>
                        <div className="settings-remove" onClick={() => { this.removeAllBUAddresses(element.bu) }}><i className="ms-Icon ms-Icon--Delete" aria-hidden="true"></i></div>
                      </Card.Header>
                      <Accordion.Collapse eventKey={element.bu}>
                        <Card.Body className="domain-settings-card-body">
                          <div className="settings-change-domain" onClick={() => { this.editBUAddressesSelected(element.bu) }}><i className="ms-Icon ms-Icon--Edit" aria-hidden="true"></i></div>
                          <form className="addresses-settings-form">
                            {this.state.form.bu !== element.bu &&
                              <div className="settings-change-addresses" onClick={() => { this.download(element.bu) }}><i className="ms-Icon ms-Icon--Download" aria-hidden="true"></i></div>
                            }
                            {this.state.form.bu === element.bu &&
                              <div>
                                <div className="settings-change-addresses" onClick={() => { this.download(element.bu) }}><i className="ms-Icon ms-Icon--Download" aria-hidden="true"></i></div>
                                <div className="settings-change-addresses" onClick={() => { this.upload.click() }}><i className="ms-Icon ms-Icon--Upload" aria-hidden="true"></i></div>
                                <input ref={(ref) => this.upload = ref} type="file" accept="*.json" style={{ display: "none" }} onChange={this.onChangeFile.bind(this)} />
                                {this.state.form.fileName &&
                                  <div>
                                    <span>Import {this.state.form.fileName} with {this.state.form.addressesCount} allowed addresses.</span>
                                  </div>
                                }
                              </div>
                            }
                            {this.state.form.bu === element.bu &&
                              <div className="form-group">
                                {this.state.form.addressesCount > 0 &&
                                  <span className="btn btn-success btn-success-engie settings-save" onClick={() => this.updateAddresses()}>Submit</span>
                                }
                                {!this.state.form.addressesCount &&
                                  <span className="btn btn-success btn-success-engie settings-save disabled">Submit</span>
                                }
                              </div>
                            }
                          </form>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>;
                  })}
                </Accordion>

              </div>
            }
            {this.state.selectedMenu === 'admins' && this.state.selectedAction === 'change' &&
              <div id="form" className="settings-block container">
                <div className="settings-title">
                  Admins
                </div>
                <div className="settings-subtitle">
                  <span>CHANGE</span>
                </div>
                {this.state.admins.map((element) => {
                  return <div key={element} className="settings-form engie-blue change">
                    <span>{element}</span>
                    <div className="settings-remove" onClick={() => { this.removeAdmin(element) }}><i className="ms-Icon ms-Icon--UserRemove" aria-hidden="true"></i></div>
                  </div>;
                })}

              </div>
            }
          </div>
          {/* <Toast>
            <Toast.Header>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded mr-2"
                alt=""
              />
              <strong className="mr-auto">Bootstrap</strong>
              <small>11 mins ago</small>
            </Toast.Header>
            <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body>
          </Toast> */}
        </div>
      </div>
    );
  }
});
