import { withOktaAuth } from '@okta/okta-react';
import React, { Component } from 'react';
import config from './config';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { countryArrray } from './Utils/country.js';
import { jobfamilies } from './Utils/jobfamilies.js';

export default withOktaAuth(class Home extends Component {
  constructor() {
    super();
    this.timeout = null;

    this.state = {
      isAuthenticated: false,
      showIntuitiveResults: true,
      results: [],
      CSVExportResults: [],
      intuitiveResults: [],
      scrollY: 0,
      scrolled: 0,
      index: 1,
      noMoreResult: false,
      limit: 30,
      globalQuery: localStorage.getItem("globalQuery") ? localStorage.getItem("globalQuery") : "",
      employeeTypeQuery: localStorage.getItem("employeeTypeQuery") ? localStorage.getItem("employeeTypeQuery") : "",
      groupIdQuery: localStorage.getItem("groupIdQuery") ? localStorage.getItem("groupIdQuery") : "",
      companyQuery: localStorage.getItem("companyQuery") ? localStorage.getItem("companyQuery") : "",
      entityQuery: localStorage.getItem("entityQuery") ? localStorage.getItem("entityQuery") : "",
      firstNameQuery: localStorage.getItem("firstNameQuery") ? localStorage.getItem("firstNameQuery") : "",
      lastNameQuery: localStorage.getItem("lastNameQuery") ? localStorage.getItem("lastNameQuery") : "",
      primaryPhoneQuery: localStorage.getItem("primaryPhoneQuery") ? localStorage.getItem("primaryPhoneQuery") : "",
      C_businessCategoryQuery: localStorage.getItem("C_businessCategoryQuery") ? localStorage.getItem("C_businessCategoryQuery") : "",
      C_countryNameQuery: localStorage.getItem("C_countryNameQuery") ? localStorage.getItem("C_countryNameQuery") : "",
      cityQuery: localStorage.getItem("cityQuery") ? localStorage.getItem("cityQuery") : "",
      C_houseIdentifierQuery: localStorage.getItem("C_houseIdentifierQuery") ? localStorage.getItem("C_houseIdentifierQuery") : "",
      titleQuery: localStorage.getItem("titleQuery") ? localStorage.getItem("titleQuery") : "",
      gbuQuery: localStorage.getItem("gbuQuery") ? localStorage.getItem("gbuQuery") : "",
      GBUs: [],
      ready: false,
      enum: {
        Countries: countryArrray,
        JobFamilies: jobfamilies
      },
      searchcss: "form-row",
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClick = this.handleClick.bind(this);

    this.setCsvInstance = this.setCsvInstance.bind(this);
  }

  async componentDidMount() {
    document.addEventListener('click', this.handleClick);
    window.addEventListener('scroll', this.handleScroll);
    this.setState({ isAuthenticated: this.props.authState?.isAuthenticated }, () => {
      this.getGBUs();
      this.getInfo();
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps?.authState && this.props?.authState && prevProps.authState.isAuthenticated !== this.props.authState.isAuthenticated) {
      this.setState({ isAuthenticated: this.props.authState?.isAuthenticated });
    }
  }

  async componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    document.removeEventListener('click', this.handleClick);
  }

  async getInfo() {
    this.setState({ ready: false });
    if (this.state.showIntuitiveResults) {
      this.handleClick();
    }
    try {
      const accessToken = this.props.authState.accessToken.accessToken;
      let url = new URL(config.env.resourceServer.getOktaUsersUrl);

      //Handle Global Search
      let formatedGlobalQuery = this.state.globalQuery ? this.state.globalQuery.slice() : "";
      let updatedGroupIdQuery = "";
      let updatedPhoneNumberQuery = "";
      let updatedGBUQuery = "";
      let updatedCountryQuery = "";

      //Handle GroupID format
      if (this.state.globalQuery.match(/^([a-zA-Z]{2}[0-9]{4})(-[a-zA-Z])?$|^([a-zA-Z]{3}[0-9]{3})(-[a-zA-Z])?$|^([a-zA-Z]{4}[0-9]{2})(-[a-zA-Z])?$/g)) {
        updatedGroupIdQuery = formatedGlobalQuery.slice();
        formatedGlobalQuery = "";
      } else if (this.state.globalQuery.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s0-9]*$/g)) {
        //Handle phone number format
        updatedPhoneNumberQuery = formatedGlobalQuery.substr(0, 1) === "0" ? formatedGlobalQuery.slice(1).replace(/[^0-9]/g, "") : formatedGlobalQuery.slice().replace(/[^0-9]/g, "");
        formatedGlobalQuery = "";
      } else if (this.state.GBUs.find(gbu => gbu.Name.replace(/\s/g, '').toUpperCase() === this.state.globalQuery.replace(/\s/g, '').toUpperCase() || gbu.Code.toUpperCase() === this.state.globalQuery.replace(/\s/g, '').toUpperCase())) {
        //Handle for GBUs
        updatedGBUQuery = this.state.GBUs.find(gbu => gbu.Name.replace(/\s/g, '').toUpperCase() === this.state.globalQuery.replace(/\s/g, '').toUpperCase() || gbu.Code.toUpperCase() === this.state.globalQuery.replace(/\s/g, '').toUpperCase()).Code;
        formatedGlobalQuery = "";
      }
      // else if (this.state.enum.Countries.find(co => co.name.replace(/\s/g, '').toUpperCase() === this.state.globalQuery.replace(/\s/g, '').toUpperCase() || co.id.toUpperCase() === this.state.globalQuery.replace(/\s/g, '').toUpperCase())) {
      //   //Handle for Countries
      //   updatedCountryQuery = this.state.enum.Countries.find(co => co.name.replace(/\s/g, '').toUpperCase() === this.state.globalQuery.replace(/\s/g, '').toUpperCase() || co.id.toUpperCase() === this.state.globalQuery.replace(/\s/g, '').toUpperCase()).name;
      //   formatedGlobalQuery = "";
      // }

      let params = {
        globalQuery: formatedGlobalQuery,
        groupIdQuery: this.state.groupIdQuery ? this.state.groupIdQuery : updatedGroupIdQuery,
        companyQuery: this.state.companyQuery,
        entityQuery: this.state.entityQuery,
        firstNameQuery: this.state.firstNameQuery,
        lastNameQuery: this.state.lastNameQuery,
        primaryPhoneQuery: this.state.primaryPhoneQuery ? this.state.primaryPhoneQuery : updatedPhoneNumberQuery,
        C_businessCategoryQuery: this.state.C_businessCategoryQuery,
        C_countryNameQuery: this.state.C_countryNameQuery ? this.state.C_countryNameQuery : updatedCountryQuery,
        cityQuery: this.state.cityQuery,
        C_houseIdentifierQuery: this.state.C_houseIdentifierQuery,
        titleQuery: this.state.titleQuery,
        employeeTypeQuery: this.state.employeeTypeQuery,
        gbuQuery: this.state.gbuQuery ? this.state.gbuQuery : updatedGBUQuery,
        limit: this.state.limit,
        after: (this.state.index - 1) * this.state.limit
      }
      url.search = new URLSearchParams(params);
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      if (response.status !== 200) {
        this.setState({ failed: true });
        return;
      }

      const data = await response.json();
      let results = this.state.results;
      data.forEach((element) => {
        results.push(element);
      });

      data.length < this.state.limit ?
        this.setState({ results: results, failed: false, noMoreResult: true }) :
        this.setState({ results: results, failed: false, noMoreResult: false });


      window.scrollTo(0, this.state.scrollY);

      data.forEach((element) => {
        this.getPhoto(element.login);
      });
    } catch (err) {
      this.setState({ failed: true });
      /* eslint-disable no-console */
      console.error(err);
    }
    this.setState({ ready: true });
  }

  async getGBUs() {
    try {
      var currentAccessToken = this.props.authState.accessToken.accessToken;
      const response = await fetch(config.env.resourceServer.getGBUsUrl + "?Status=Valid&order=Name ASC&select=Name,Code&index=0&itemPerPage=1000", {
        headers: {
          Authorization: `Bearer ${currentAccessToken}`
        },
      });

      if (response.status !== 200) {
        return;
      }

      const data = await response.json();
      this.setState({ GBUs: data });
    } catch (err) {
      console.error(err);
    }
  }

  async getCSVExportInfo() {
    try {
      document.getElementById("CSVExportButton").disabled = true;
      const accessToken = this.props.authState.accessToken.accessToken;
      let url = new URL(config.env.resourceServer.getOktaUsersUrl);

      //Handle Global Search
      let formatedGlobalQuery = this.state.globalQuery ? this.state.globalQuery.slice() : "";
      let updatedGroupIdQuery = "";
      let updatedPhoneNumberQuery = "";
      let updatedGBUQuery = "";
      let updatedCountryQuery = "";

      //Handle GroupID format
      if (this.state.globalQuery.match(/^([a-zA-Z]{2}[0-9]{4})(-[a-zA-Z])?$|^([a-zA-Z]{3}[0-9]{3})(-[a-zA-Z])?$|^([a-zA-Z]{4}[0-9]{2})(-[a-zA-Z])?$/g)) {
        updatedGroupIdQuery = formatedGlobalQuery.slice();
        formatedGlobalQuery = "";
      } else if (this.state.globalQuery.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s0-9]*$/g)) {
        //Handle phone number format
        updatedPhoneNumberQuery = formatedGlobalQuery.substr(0, 1) === "0" ? formatedGlobalQuery.slice(1).replace(/[^0-9]/g, "") : formatedGlobalQuery.slice().replace(/[^0-9]/g, "");
        formatedGlobalQuery = "";
      } else if (this.state.GBUs.find(gbu => gbu.Name.replace(/\s/g, '').toUpperCase() === this.state.globalQuery.replace(/\s/g, '').toUpperCase() || gbu.Code.toUpperCase() === this.state.globalQuery.replace(/\s/g, '').toUpperCase())) {
        //Handle for GBUs
        updatedGBUQuery = this.state.GBUs.find(gbu => gbu.Name.replace(/\s/g, '').toUpperCase() === this.state.globalQuery.replace(/\s/g, '').toUpperCase() || gbu.Code.toUpperCase() === this.state.globalQuery.replace(/\s/g, '').toUpperCase()).Code;
        formatedGlobalQuery = "";
      }
      // else if (this.state.enum.Countries.find(co => co.name.replace(/\s/g, '').toUpperCase() === this.state.globalQuery.replace(/\s/g, '').toUpperCase() || co.id.toUpperCase() === this.state.globalQuery.replace(/\s/g, '').toUpperCase())) {
      //   //Handle for Countries
      //   updatedCountryQuery = this.state.enum.Countries.find(co => co.name.replace(/\s/g, '').toUpperCase() === this.state.globalQuery.replace(/\s/g, '').toUpperCase() || co.id.toUpperCase() === this.state.globalQuery.replace(/\s/g, '').toUpperCase()).name;
      //   formatedGlobalQuery = "";
      // }

      let params = {
        globalQuery: formatedGlobalQuery,
        groupIdQuery: this.state.groupIdQuery ? this.state.groupIdQuery : updatedGroupIdQuery,
        companyQuery: this.state.companyQuery,
        entityQuery: this.state.entityQuery,
        firstNameQuery: this.state.firstNameQuery,
        lastNameQuery: this.state.lastNameQuery,
        primaryPhoneQuery: this.state.primaryPhoneQuery ? this.state.primaryPhoneQuery : updatedPhoneNumberQuery,
        C_businessCategoryQuery: this.state.C_businessCategoryQuery,
        C_countryNameQuery: this.state.C_countryNameQuery ? this.state.C_countryNameQuery : updatedCountryQuery,
        cityQuery: this.state.cityQuery,
        C_houseIdentifierQuery: this.state.C_houseIdentifierQuery,
        titleQuery: this.state.titleQuery,
        employeeTypeQuery: this.state.employeeTypeQuery,
        gbuQuery: this.state.gbuQuery ? this.state.gbuQuery : updatedGBUQuery,
        limit: 1000,
        after: 0
      }
      url.search = new URLSearchParams(params);
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      const data = await response.json();
      let results = [];
      data.forEach((element) => {
        results.push(element);
      });

      this.setState({ CSVExportResults: results })

      if (this.csvInstanceRef && this.csvInstanceRef.link) {
        this.csvInstanceRef.link.click();
      }

      setTimeout(function () {
        document.getElementById('CSVExportButton').disabled = false;
      }, 2000);

    } catch (err) {
      /* eslint-disable no-console */
      console.error(err);
    }
  }

  async getPhoto(userId) {
    try {
      const accessToken = this.props.authState.accessToken.accessToken;
      const response = await fetch(config.env.resourceServer.getOktaUserPhotoUrl + "?userId=" + userId + "&size=240x240", {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
      });

      if (response.status !== 200) {
        return;
      }

      const data = await response.json();
      if (!data.error) {
        let domUser = document.getElementById(userId);
        if (domUser) {
          domUser.src = data.image;
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  async getIntuitivePhoto(userId) {
    try {
      const accessToken = this.props.authState.accessToken.accessToken;
      const response = await fetch(config.env.resourceServer.getOktaUserPhotoUrl + "?userId=" + userId + "&size=240x240", {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
      });

      const data = await response.json();

      let domUser = document.getElementById(`intuitive-${userId}`);
      if (domUser) {
        domUser.src = data.image ? data.image : "img/default_profile_photo.png";
      }
    } catch (err) {
      console.error(err);
    }
  }

  async getIntuitiveInfo() {
    try {
      let results = [];
      this.setState({ intuitiveResults: results });
      const accessToken = this.props.authState.accessToken.accessToken;
      let url = new URL(config.env.resourceServer.getOktaUsersUrl);

      let formatedGlobalQuery = this.state.globalQuery ? this.state.globalQuery.slice() : "";
      let updatedGroupIdQuery = "";
      let updatedPhoneNumberQuery = "";

      //Handle GroupID format
      if (this.state.globalQuery.match(/^([a-zA-Z]{2}[0-9]{4})(-[a-zA-Z])?$|^([a-zA-Z]{3}[0-9]{3})(-[a-zA-Z])?$|^([a-zA-Z]{4}[0-9]{2})(-[a-zA-Z])?$/g)) {
        updatedGroupIdQuery = formatedGlobalQuery.slice();
        formatedGlobalQuery = "";
      } else if (this.state.globalQuery.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s0-9]*$/g)) {
        //Handle phone number format
        updatedPhoneNumberQuery = formatedGlobalQuery.substr(0, 1) === "0" ? formatedGlobalQuery.slice(1).replace(/[^0-9]/g, "") : formatedGlobalQuery.slice().replace(/[^0-9]/g, "");
        formatedGlobalQuery = "";
      }

      let params = {
        globalQuery: formatedGlobalQuery,
        groupIdQuery: updatedGroupIdQuery,
        primaryPhoneQuery: updatedPhoneNumberQuery,
        limit: 3,
        after: 0
      }
      url.search = new URLSearchParams(params);
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      const data = await response.json();
      data.forEach((element) => {
        results.push(element);
      });

      this.setState({ intuitiveResults: results });

      data.forEach((element) => {
        this.getIntuitivePhoto(element.login);
      });
    } catch (err) {
      /* eslint-disable no-console */
      console.error(err);
    }
  }

  handleScroll = () => {
    let scrollY = window.scrollY || document.documentElement.scrollTop;
    // let limit = Math.max(document.body.scrollHeight, document.body.offsetHeight,
    //   document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
    if (scrollY > 120) {
      this.setState({ searchcss: "form-row container fixed-search" });
    }
    else {
      this.setState({ searchcss: "form-row container" });
    }

    if (((window.innerHeight + window.scrollY) >= document.body.offsetHeight) && this.state.ready && !this.state.noMoreResult && !this.state.failed) {
      this.setState({ scrolled: (this.state.scrolled + this.state.limit), index: (this.state.index + 1), scrollY: scrollY });
      this.getInfo();
    }
  }

  handleAdvancedSearchSubmit = () => {
    this.setState({ scrolled: 0, scrollY: 0, index: 1, results: [] });
    this.getInfo();
  }

  handleInputChange = (e) => {
    let stateObj = this.state;
    stateObj[e.target.id] = e.target.value;
    this.setState({ [e.target.id]: e.target.value });
    if (e.target.id === "globalQuery" && e.target.value.length > 2) {
      clearTimeout(this.timeout);

      // Make a new timeout set to go off in 800ms
      this.timeout = setTimeout(() => {
        this.getIntuitiveInfo();
        if (!this.state.showIntuitiveResults) {
          this.setState({ showIntuitiveResults: true });
        }
      }, 500);
    }
    localStorage.setItem(e.target.id, e.target.value);
  }

  handleClick() {
    if (this.state.showIntuitiveResults) {
      const currentState = this.state.showIntuitiveResults;
      this.setState({ showIntuitiveResults: !currentState });
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  setCsvInstance(node) {
    this.csvInstanceRef = node;
  }

  showFilters = () => {
    const currentState = this.state.showFilters;
    this.setState({ showFilters: !currentState });
  }

  clearFilters = () => {
    this.setState({
      globalQuery: "",
      employeeTypeQuery: "",
      groupIdQuery: "",
      companyQuery: "",
      entityQuery: "",
      firstNameQuery: "",
      lastNameQuery: "",
      primaryPhoneQuery: "",
      C_businessCategoryQuery: "",
      C_countryNameQuery: "",
      cityQuery: "",
      C_houseIdentifierQuery: "",
      titleQuery: "",
      gbuQuery: "",
      results: [],
      index: 1
    }, () => this.getInfo());

  }

  getFormattedDateTime() {
    var date = new Date().toJSON().slice(0, 10);
    var time = new Date().toJSON().slice(11, 19);
    return date + "-" + time;
  }

  render() {
    return (
      <div>
        {!this.state.ready && <div className="probootstrap-loader"></div>}
        <div>
          {!this.state.isAuthenticated &&
            <div>
              <p>
                You need to be logged to access to Engie Directory.
              </p>
            </div>
          }
          {(this.state.ready || this.state.index > 1) &&
            <div>
              <section className="probootstrap-slider flexslider2 page-inner">
                <div className="overlay engiegradient-background"></div>
                <div className="probootstrap-wrap-banner">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="page-title">
                          <div className="probootstrap-breadcrumbs">
                            <a href="/">Home</a><span>Results</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="probootstrap-section probootstrap-section-lighter">
                <div className="container">
                  <div className="row">
                    <form className="col-md-12 filterform">
                      <div className={this.state.searchcss}>
                        <div className="form-group col-md-10">
                          <input type="text" className="form-control" value={this.state.globalQuery} onChange={this.handleInputChange} id="globalQuery" autoComplete="off" />
                          {this.state.intuitiveResults.length > 0 &&
                            <div className={this.state.showIntuitiveResults ? "dropdown-menu intuitive-menu show" : "dropdown-menu"}>
                              {this.state.intuitiveResults.map((element) => {
                                var a = this.state.intuitiveResults.indexOf(element);
                                return <a ref={this.setWrapperRef} key={a} href={'/Profile?' + element.login} className="dropdown-item intuitive-result">
                                  <div className="profile-img intuitive-img">
                                    <img id={"intuitive-" + element.login} src="img/default_profile_photo.png" className="img-responsive" alt="Free HTML5 Template by uicookies.com" />
                                  </div>
                                  <div className="intuitive-description">
                                    {element.firstName || element.lastName ? element.firstName + " " + element.lastName.toUpperCase() : "UNKNWOWN USER"}</div>
                                </a>
                              })}
                              <div ref={this.setWrapperRef} className="intuitive-result">
                                <button onClick={this.handleAdvancedSearchSubmit} className="dropdown-item">
                                  <FormattedMessage id="home.more" defaultMessage="More" />
                                </button></div>
                            </div>
                          }
                        </div>
                        <div className="form-group col-md-2">
                          <div onClick={this.handleAdvancedSearchSubmit}>
                            <button className="btn btn-success btn-success-engie right" type="submit">
                              <i className="icon-magnifying-glass t2"></i> <FormattedMessage id="result.filter" defaultMessage="Filter" /></button>
                          </div>
                        </div>
                      </div>
                      {!this.state.showFilters &&
                        <div className="marginbot">
                          <a href="# " role="button" onClick={this.showFilters} className="btn btn-success btn-success-engie ml-1">
                            + <FormattedMessage id="home.advanced_search" defaultMessage="Filters" />
                          </a>
                          <a href="# " role="button" onClick={this.clearFilters} className="btn btn-success btn-success-engie ml-1">
                            <FormattedMessage id="result.clearFilters" defaultMessage="Clear filters" />
                          </a>
                        </div>
                      }
                      {this.state.showFilters &&
                        <div>
                          <div className="marginbot">
                            <a href="# " role="button" onClick={this.showFilters} className="btn btn-success btn-success-engie ml-1">
                              - <FormattedMessage id="home.advanced_search" defaultMessage="Filters" />
                            </a>
                            <a href="# " role="button" onClick={this.clearFilters} className="btn btn-success btn-success-engie ml-1">
                              <FormattedMessage id="result.clearFilters" defaultMessage="Clear filters" />
                            </a>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-3">
                              <label id="lastnameLabel"><FormattedMessage id="field.lastname" defaultMessage="Last name" /></label>
                              <input type="text" className="form-control" value={this.state.lastNameQuery} onChange={this.handleInputChange} id="lastNameQuery" />
                            </div>
                            <div className="form-group col-md-3">
                              <label id="firstnameLabel"><FormattedMessage id="field.firstname" defaultMessage="First name" /></label>
                              <input type="text" className="form-control" value={this.state.firstNameQuery} onChange={this.handleInputChange} id="firstNameQuery" />
                            </div>
                            <div className="form-group col-md-3">
                              <label id="groupIdLabel"><FormattedMessage id="field.groupid" defaultMessage="Group ID" /></label>
                              <input type="text" className="form-control" value={this.state.groupIdQuery} onChange={this.handleInputChange} id="groupIdQuery" />
                            </div>
                            <div className="form-group col-md-3">
                              <label id="employeeTypeLabel"><FormattedMessage id="field.employeetype" defaultMessage="Employee Type" /></label>
                              <select id="employeeTypeQuery" className="form-control" value={this.state.employeeTypeQuery} onChange={this.handleInputChange}>
                                <option value="">Internal/External</option>
                                <option value="I">Internal</option>
                                <option value="E">External</option>
                              </select>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-3">
                              <label id="gbuLabel">GBU</label>
                              <select className="form-control" value={this.state.gbuQuery} onChange={this.handleInputChange} id="gbuQuery">
                                <option value=""></option>
                                {this.state.GBUs.map((element) => {
                                  return <option key={element.Code} value={element.Code}>{element.Name}</option>;
                                })}
                              </select>
                            </div>
                            <div className="form-group col-md-3">
                              <label id="companyLabel"><FormattedMessage id="field.company" defaultMessage="Company" /></label>
                              <input type="text" className="form-control" value={this.state.companyQuery} onChange={this.handleInputChange} id="companyQuery" />
                            </div>
                            <div className="form-group col-md-3">
                              <label id="jobFamilyLabel"><FormattedMessage id="field.jobfamily" defaultMessage="Job family" /></label>
                              <select className="form-control" value={this.state.C_businessCategoryQuery} onChange={this.handleInputChange} id="C_businessCategoryQuery">
                                <option value=""></option>
                                {this.state.enum.JobFamilies.map((element) => {
                                  return <FormattedMessage id={"field.jobfamily." + element} key={element}>
                                    {(message) => <option key={element} value={element}>{message ? message : element}</option>}
                                  </FormattedMessage>;
                                })}
                              </select>
                            </div>
                            <div className="form-group col-md-3">
                              <label id="functionLabel"><FormattedMessage id="field.function" defaultMessage="Function" /></label>
                              <input type="text" className="form-control" value={this.state.titleQuery} onChange={this.handleInputChange} id="titleQuery" />
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-3">
                              <label id="phonenumberLabel"><FormattedMessage id="field.phonenumber" defaultMessage="Phone number" /></label>
                              <input type="text" className="form-control" value={this.state.primaryPhoneQuery} onChange={this.handleInputChange} id="primaryPhoneQuery" />
                            </div>
                            <div className="form-group col-md-3">
                              <label id="countryLabel"><FormattedMessage id="field.country" defaultMessage="Country" /></label>
                              <select className="form-control" value={this.state.C_countryNameQuery} onChange={this.handleInputChange} id="C_countryNameQuery">
                                <option value=""></option>
                                {this.state.enum.Countries.map((element) => {
                                  return <option key={element.id} value={element.name}>{element.name}</option>;
                                })}
                              </select>
                            </div>
                            <div className="form-group col-md-3">
                              <label id="cityLabel"><FormattedMessage id="field.city" defaultMessage="City" /></label>
                              <input type="text" className="form-control" value={this.state.cityQuery} onChange={this.handleInputChange} id="cityQuery" />
                            </div>
                            <div className="form-group col-md-3">
                              <label id="siteLabel"><FormattedMessage id="field.site" defaultMessage="Site" /></label>
                              <input type="text" className="form-control" value={this.state.C_houseIdentifierQuery} onChange={this.handleInputChange} id="C_houseIdentifierQuery" />
                            </div>
                          </div>
                        </div>
                      }
                    </form>
                  </div>
                  <div className="row">
                    {this.state.results.length > 0 &&
                      this.state.results.map((element) => {
                        var a = this.state.results.indexOf(element);
                        return <Link to={'/Profile?' + element.login} key={a} className="col-md-3 col-sm-6 probootstrap-customresultlink probootstrap-card probootstrap-listing">
                          <div className="probootstrap-card probootstrap-person">
                            <div className="probootstrap-card-media">
                              <img id={element.login} src="img/default_profile_photo.png" className="img-responsive" alt="Free HTML5 Template by uicookies.com" />
                            </div>
                            <div className="probootstrap-card-text">
                              {/*this.state.groupIdQuery || updatedGroupIdQuery &&
                                  <span className="engie-blue">{element.C_groupIdentifier}</span>*/
                              }
                              <h2 className="probootstrap-card-heading mb0">{element.firstName} {element.lastName}</h2>
                              <span className="engie-blue">{element.title}</span>
                              <p><small>{element.email}</small></p>
                            </div>
                          </div>
                        </Link>;
                      })
                    }
                  </div>
                  {this.state.results.length === 0 &&
                    <div>
                      No result found.
                    </div>
                  }
                </div>
              </section>
            </div>
          }
        </div>
      </div>
    );
  }
});
